import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { HomeLayout } from '../module/server/HomeLayout';
import { Playlist } from "../module/server/Playlist"
import Loader from "react-js-loader"
import SelectBlockModal from '../component/home-layout/SelectBlockModal';
import { HomeBlock } from '../module/server/HomeBlock';

function SingleHomeLayout() {

    const [layout, setLayout] = useState(null);
    const [blocksAndOrder, setBlocksAndOrder] = useState([])
    const [playlists, setPlaylists] = useState([])
    const [showAddBlock, setShowAddBlock] = useState(false)
    const [dragId, setDragId] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const [selectedBlockIds, setSelectedBlockIds] = useState([])

    const { layoutId } = useParams();
    const navigate = useNavigate();
    let dragTargetId = null


    useEffect(() => {
        if (layout !== null) { return }
        (async () => {
            try {
                const l = await HomeLayout.layout(layoutId)
                setLayout(l)

                let blocks = []
                if (l.blocks !== undefined) {
                    for (const blockId of l.blocks) {
                        const block = await HomeBlock.block(blockId)
                        blocks.push({
                            block: block,
                            order: l.blocksOrder.find(e => e.id === block.id).order
                        })
                    }

                    setBlocksAndOrder(blocks)
                    fetchPlaylists(blocks.map(e => e.block))
                }
            } catch (err) {
                alert(err)
            }
        })()
    }, [layoutId, layout]);

    const handleAddBlocks = (newBlocks) => {
        const maxOrder = Math.max(blocksAndOrder.map(e => e.order))
        const newBlocksAndOrder = newBlocks.map((e, i) => {
            const existingBlock = blocksAndOrder.find(b => b.block.id === e.id)
            return {
                block: e,
                order: existingBlock ? existingBlock.order : (maxOrder ? maxOrder + 1 : i + 1)
            }
        })
        setBlocksAndOrder(newBlocksAndOrder)
        fetchPlaylists(newBlocks)
    }

    function fetchPlaylists(blocks) {
        const playlistIds = blocks
            .map(b => b.playlists.flat())
            .reduce((prev, current) => prev.concat(current), [])
            .filter((e, i, self) => self.indexOf(e) === i);


        (async () => {
            let playlists = []
            for (const id of playlistIds) {
                const playlist = await Playlist.playlist(id)
                playlists.push(playlist)
            }

            setPlaylists(playlists)
        })()
    }

    function handleMoveUp(id) {
        const sourceIndex = blocksAndOrder.findIndex(e => e.block.id === id)
        const targetIndex = sourceIndex - 1
        swapBlocks(targetIndex, sourceIndex)
    }

    function handleMoveDown(id) {
        const sourceIndex = blocksAndOrder.findIndex(e => e.block.id === id)
        const targetIndex = sourceIndex + 1
        swapBlocks(targetIndex, sourceIndex)
    }

    function handleSave() {
        setIsSaving(true)
        HomeLayout.updateBlocks(layoutId, blocksAndOrder.map(e => { return { id: e.block.id, order: e.order } }))
            .catch(e => alert(e))
            .finally(_ => setIsSaving(false))
    }

    function handleDragStart(e) {
        setDragId(e.target.id)
    }

    function handleDrop() {

        if (dragId === dragTargetId) { return }


        const targetIndex = blocksAndOrder.findIndex(e => e.block.id === dragTargetId)
        const sourceIndex = blocksAndOrder.findIndex(e => e.block.id === dragId)
        swapBlocks(targetIndex, sourceIndex)
    }

    function swapBlocks(targetIndex, sourceIndex) {
        let newBlocksAndOrder = blocksAndOrder.slice()

        if (targetIndex === -1) { return }

        if (targetIndex === 0) {
            newBlocksAndOrder[sourceIndex].order = newBlocksAndOrder[targetIndex].order / 2
        } else if (Math.abs(targetIndex - sourceIndex) === 1) {
            const order = newBlocksAndOrder[sourceIndex].order
            newBlocksAndOrder[sourceIndex].order = newBlocksAndOrder[targetIndex].order
            newBlocksAndOrder[targetIndex].order = order
        } else {
            if (targetIndex < sourceIndex) {
                newBlocksAndOrder[sourceIndex].order = (newBlocksAndOrder[targetIndex].order + newBlocksAndOrder[targetIndex - 1].order) / 2
            } else {
                newBlocksAndOrder[sourceIndex].order = (newBlocksAndOrder[targetIndex].order + newBlocksAndOrder[targetIndex + 1].order) / 2
            }
        }

        setBlocksAndOrder(newBlocksAndOrder)
    }

    function handleBlockClick(id) {
        let selectedIds = selectedBlockIds.slice()
        if (selectedIds.includes(id)) {
            selectedIds = selectedIds.filter(e => e !== id)
        } else {
            selectedIds.push(id)
        }

        setSelectedBlockIds(selectedIds)
    }

    function handleRemove() {
        const newBlocks = blocksAndOrder.filter(e => !selectedBlockIds.includes(e.block.id))
        setBlocksAndOrder(newBlocks)
        setSelectedBlockIds([])
    }

    const blockView = (block) => {
        switch (block.type) {
            case "playlist":
                return playlistBlockView(block)
            case "group":
                return groupBlockView(block)
            default: return
        }
    }

    const groupBlockView = (block) => {
        const blockPlaylists = playlists.filter(p => block.playlists.includes(p.id))

        return (
            <Fragment>
                <p className="is-size-4 has-text-weight-bold">{block.meta.title}</p>
                <div className="columns is-multiline">
                    {blockPlaylists.map(p => (
                        <Fragment key={p.id + block.id}>
                            <div className="column is-3">
                                <img draggable={false} src={p.coverImage && p.coverImage.thumbnailUrl} alt="" />
                                <p className="is-size-6 has-text-weight-medium">{p.meta.title}</p>
                            </div>
                        </Fragment>
                    ))
                    }
                </div >
                <p className="is-size-5">{block.meta.description}</p>
            </Fragment>
        )
    }

    const playlistBlockView = (block) => {
        const playlist = playlists.find(p => p.id === block.playlists[0])
        return (
            <Fragment>
                <p className="is-size-4 has-text-weight-bold">{block.meta.title}</p>
                <div className="columns">
                    <div className="column is-6">
                        {playlist &&
                            <img draggable={false} src={playlist.coverImage && playlist.coverImage.thumbnailUrl} alt="" />
                        }
                    </div>
                </div>

                <p className="is-size-5">{block.meta.description}</p>
            </Fragment>
        )
    }

    const titleView = () => (
        <Fragment>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <button className="button" onClick={() => navigate(-1)}>
                            <span className="icon">
                                <i className="fas fa-arrow-left"></i>
                            </span>
                            <span>Back</span>
                        </button>
                    </div>
                    <div className="level-item">
                        <p className='title'>{layout.name}</p>
                    </div>
                </div>
            </div>
            <div className="block">
                <div className="level">
                    <div className="level-left">
                        <button className='level-item button is-primary' onClick={() => setShowAddBlock(true)}>Add Blocks</button>
                        <button className={"level-item button is-primary " + (isSaving ? "is-loading" : "")} onClick={handleSave}>Save</button>
                        {selectedBlockIds.length > 0 &&
                            <Fragment>
                                <button className="level-item button is-light">Deselect</button>
                                <button className="level-item button is-danger is-outlined" onClick={handleRemove}>Remove</button>
                            </Fragment>
                        }
                    </div>
                </div>

            </div>
        </Fragment>
    )

    return (
        <Fragment>
            <div className='section'>
                {layout === null
                    ?
                    <div className='container' style={{ marginTop: 100 }}>
                        <Loader bgColor={"#ccc"} size={100} />
                    </div>
                    :
                    <div className='container'>
                        {titleView()}
                        <p className="block">Blocks:</p>
                        <ul>
                            {blocksAndOrder
                                .sort(function (a, b) { return a.order - b.order })
                                .map((blockOrder, i) => (
                                    <Fragment key={blockOrder.block.id}>
                                        <div className="level">
                                            <div className="level-left">
                                                {(i > 0) &&
                                                    <button className="level-item button" onClick={() => { handleMoveUp(blockOrder.block.id) }}>
                                                        <span className="icon">
                                                            <i className="fa-solid fa-arrow-up"></i>
                                                        </span>
                                                    </button>
                                                }
                                                {(i < blocksAndOrder.length - 1) &&
                                                    <button className="level-item button" onClick={() => { handleMoveDown(blockOrder.block.id) }}>
                                                        <span className="icon">
                                                            <i className="fa-solid fa-arrow-down"></i>
                                                        </span>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        <li
                                            id={blockOrder.block.id}
                                            className={"box is-clickable " + (selectedBlockIds.includes(blockOrder.block.id) ? " is-block-selected " : "")}
                                            draggable={true}
                                            onDragOver={(e) => e.preventDefault()}
                                            onDragEnter={e => {
                                                if (e.target.id.length) {
                                                    dragTargetId = e.target.id
                                                    // setDragTargetId(e.target.id)
                                                }
                                            }}
                                            onDrop={handleDrop}
                                            onDragStart={handleDragStart}
                                            onClick={() => handleBlockClick(blockOrder.block.id)}
                                        >{blockView(blockOrder.block)}</li>
                                        <hr></hr>
                                    </Fragment>
                                ))}
                        </ul>
                    </div>
                }
            </div>
            {
                showAddBlock &&
                <SelectBlockModal blocks={blocksAndOrder.map(e => e.block.id)} onAddBlocks={handleAddBlocks} allowMultipleSelection={true} onClose={() => setShowAddBlock(false)} />
            }
        </Fragment >
    )
}

export default SingleHomeLayout
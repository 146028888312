import React, { Fragment, useEffect, useState } from 'react'
import { Playlist } from '../../module/server/Playlist'
import { Link } from 'react-router-dom'

function PlaylistBlock(props) {

    const [playlist, setPlaylist] = useState(null)
    const [artworks, setArtworks] = useState([])
    const block = props.block

    useEffect(() => {
        const id = block.playlists[0]
        if (id === undefined) { return }

        (async () => {
            try {
                const pl = await Playlist.playlist(id)
                const aws = await Playlist.artworks(pl)
                setPlaylist(pl)
                setArtworks(aws)
            } catch (e) {
                alert(e)
            }
        })()
    }, [block])

    return (
        <Fragment>
            {playlist &&
                <div className="columns">
                    <div className="column is-6">
                        <Link to={"/playlists/" + playlist.id}>
                            <figure className="image">
                                <img className='has-background-color' src={playlist.coverImage.thumbnailUrl && playlist.coverImage.thumbnailUrl} width={playlist.coverImage.thumbnailWidth} height={playlist.coverImage.thumbnailHeight} alt="" />
                            </figure>
                            <span className="is-size-5 has-text-weight-bold">{playlist.meta.title}</span>
                        </Link>
                    </div>
                    <div className="column is-6">
                        <div className="columns is-multiline">
                            {artworks.sort((a, b) => a.order - b.order).map(artwork => (
                                <div key={artwork.id} className="column is-4">
                                    <figure className="is-artwork-preview">
                                        <img className='has-background-color' src={artwork.thumbnailUrl} width={artwork.thumbnailWidth} height={artwork.thumbnailHeight} alt="" />
                                    </figure>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default PlaylistBlock
import { useEffect, useState } from 'react'
import PlaylistsList from '../component/playlists/PlaylistsList.js'
import CreatePlaylist from '../component/singleArtist/CreatePlaylist.js'
import { Playlist } from '../module/server/Playlist.js'

function Playlists() {
    const [playlists, setPlaylists] = useState([])
    const [filteredPlaylists, setFilteredPlaylists] = useState([])
    const [searchQuery, setSearchQuery] = useState("")
    const [showAddPlaylist, setShowAddPlaylist] = useState(false)

    useEffect(() => {
        Playlist.all()
            .then(result => setPlaylists(result))
            .catch(err => alert(err))
    }, [])

    useEffect(() => {
        const queries = searchQuery.split(" ").map(e => e.trim().toLocaleLowerCase()).filter(e => e !== "")
        if (queries.length === 0) {
            setFilteredPlaylists(playlists)
            return;
        }

        let allFiltered = []
        for (const query of queries) {
            const filtered = playlists.filter(e => {
                const title = e.meta.title.toLowerCase()
                return (
                    title.includes(query)
                )
            })

            allFiltered = allFiltered.concat(filtered)
        }

        allFiltered = allFiltered.filter((e, i, self) => self.indexOf(e) === i);

        setFilteredPlaylists(allFiltered)
    }, [playlists, searchQuery])

    function handleRefresh() {
        Playlist.all()
            .then(result => setPlaylists(result))
            .catch(err => alert(err))

        setSearchQuery("")
    }

    return (
        <div className="section">
            <div className='block'>
                <h1 className='title'>Playlists</h1>
                <button className='button is-primary' onClick={() => setShowAddPlaylist(true)}>Add</button>
            </div>

            <div className="block">
                <input type="text" className="input" placeholder='Filter artist' defaultValue={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
            </div>

            <div className='container is-widescreen mt-5'>
                <PlaylistsList playlists={filteredPlaylists} updatePlaylists={setPlaylists} />
            </div>

            {showAddPlaylist &&
                <CreatePlaylist onRefresh={handleRefresh} onClose={() => setShowAddPlaylist(false)} />
            }

        </div>
    )
}

export default Playlists

function Images() {

    return (
        <div className='section'>
            <div className='container'>
                <h1 className='title'>Images</h1>
            </div>
        </div>
    );
}

export default Images
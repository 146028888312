import React, { useState, useEffect, Fragment } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Loader from "react-js-loader"
import { Playlist } from '../module/server/Playlist.js';
import EditPlaylistInfoModal from '../component/EditPlaylistInfoModal.js';
import VisibilityButtons from '../component/playlists/VisibilityButtons.js';
import PremiumButtons from '../component/playlists/PremiumButtons.js';

function SinglePlaylist(props) {

    const [playlist, setPlaylist] = useState(undefined);
    const [artworksAndOrder, setArtworksAndOrder] = useState([])
    const [selectedArtworkIds, setSelectedArtworkIds] = useState([])
    const [isSaving, setIsSaving] = useState(false)
    const [dragId, setDragId] = useState(null)
    const [showEditInfo, setShowEditInfo] = useState(false)

    const { playlistId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const fetchedPlaylist = await Playlist.playlist(playlistId)
                const fetchedArtworks = await Playlist.artworks(fetchedPlaylist)

                let artworksAndOrder = fetchedPlaylist.artworksOrder.slice()
                artworksAndOrder.forEach((e, i) => {
                    const artwork = fetchedArtworks.find(a => a.id === e.id)
                    e.artwork = artwork
                })

                setPlaylist(fetchedPlaylist)
                setArtworksAndOrder(artworksAndOrder)
            } catch (e) {
                alert(e)
            }
        })()

        Playlist.playlist(playlistId)
            .then(result => {
                setPlaylist(result)
            })
            .catch(e => alert(e))

    }, [playlistId])

    function handleArtworkClick(media) {
        let selectedIds = Array.from(selectedArtworkIds)
        if (selectedIds.includes(media.id)) {
            selectedIds = selectedIds.filter(e => e !== media.id)
        } else {
            selectedIds.push(media.id)
        }

        setSelectedArtworkIds(selectedIds)
    }

    function handleRemove() {
        setArtworksAndOrder(artworksAndOrder.filter(e => !selectedArtworkIds.includes(e.artwork.id)))
        setSelectedArtworkIds([])
    }

    function handleSave() {
        setIsSaving(true)
        Playlist.update(playlist, artworksAndOrder)
            .catch(e => alert(e))
            .finally(_ => setIsSaving(false))
    }

    function handleDragStart(e) {
        setDragId(e.target.id)
    }

    function handleDrop(e) {
        let newArtworksOrdered = artworksAndOrder.slice()
        const targetId = e.target.closest(".is-artwork-preview").id
        const targetIndex = newArtworksOrdered.findIndex(e => e.artwork.id === targetId)
        const sourceIndex = newArtworksOrdered.findIndex(e => e.artwork.id === dragId)

        if (targetIndex === 0) {
            newArtworksOrdered[sourceIndex].order = newArtworksOrdered[targetIndex].order / 2
        } else if (Math.abs(targetIndex - sourceIndex) === 1) {
            const order = newArtworksOrdered[sourceIndex].order
            newArtworksOrdered[sourceIndex].order = newArtworksOrdered[targetIndex].order
            newArtworksOrdered[targetIndex].order = order
        } else {
            if (targetIndex < sourceIndex) {
                newArtworksOrdered[sourceIndex].order = (newArtworksOrdered[targetIndex].order + newArtworksOrdered[targetIndex - 1].order) / 2
            } else {
                newArtworksOrdered[sourceIndex].order = (newArtworksOrdered[targetIndex].order + newArtworksOrdered[targetIndex + 1].order) / 2
            }

        }

        setArtworksAndOrder(newArtworksOrdered)
    }

    function handleRefresh() {
        Playlist.playlist(playlistId)
            .then(e => setPlaylist(e))
            .catch(e => alert(e))
    }

    const titleView = () => (
        <Fragment>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <button className="button" onClick={() => navigate(-1)}>
                            <span className="icon">
                                <i className="fas fa-arrow-left"></i>
                            </span>
                            <span>Back</span>
                        </button>
                    </div>
                    <div className="level-item">
                        <p className='title'>{playlist.meta.title}</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )

    const functionalButtons = () => (
        <div className="level">
            <div className="level-left">
                <div className="level-item"><VisibilityButtons playlist={playlist}></VisibilityButtons></div>
                <div className="level-item"><PremiumButtons playlist={playlist}></PremiumButtons></div>
            </div>
        </div>
    )


    const coverImageFragment = () => (
        <div className="block">
            {playlist.coverImage && <img className='has-background-color' src={playlist.coverImage.thumbnailUrl} alt="" />}
        </div>
    )

    const artworksView = () => (
        <div className="block">
            <span className='is-size-6 has-text-weight-bold'>Artworks</span>
            <div className="level">
                <div className="level-left">
                    <button className={"button level-item is-primary " + (isSaving ? "is-loading" : "")} onClick={handleSave}>Save</button>
                    {selectedArtworkIds.length > 0 &&
                        <Fragment>
                            <button className="level-item button is-light" onClick={(_ => setSelectedArtworkIds([]))}>Deselect</button>
                            <button className="button level-item is-danger is-outlined" onClick={handleRemove}>Remove</button>

                        </Fragment>
                    }
                </div>
            </div>
            <div className="columns is-vcentered is-multiline">
                {artworksAndOrder.sort(function (a, b) { return a.order - b.order }).map(artworkOrder => (
                    <div className="column is-3" key={artworkOrder.artwork.id}>
                        <figure className="is-artwork-preview is-clickable"
                            id={artworkOrder.artwork.id}
                            onClick={e => handleArtworkClick(artworkOrder.artwork)}
                            draggable={true}
                            onDragOver={(ev) => ev.preventDefault()}
                            onDrop={handleDrop}
                            onDragStart={handleDragStart}>
                            <img
                                className={
                                    "has-background-color" + (selectedArtworkIds.includes(artworkOrder.artwork.id) && " is-selected ")}
                                alt=""
                                width={artworkOrder.artwork.thumbnailWidth}
                                height={artworkOrder.artwork.thumbnailHeight}
                                src={artworkOrder.artwork.thumbnailUrl}
                                draggable={false}
                            />
                        </figure>
                    </div>
                ))}
            </div>
        </div>
    )

    return (
        <div className='section'>
            {playlist === undefined
                ?
                <div className='container' style={{ marginTop: 100 }}>
                    <Loader bgColor={"#ccc"} size={100} />
                </div>
                :
                <div className='container'>
                    {titleView()}
                    {functionalButtons()}

                    <div className="box">
                        {coverImageFragment()}
                        <div className="block">
                            <p>{playlist.meta.description}</p>
                        </div>

                        <button className="button is-light" onClick={() => setShowEditInfo(true)}>
                            <span className="icon is-small">
                                <i className="fa-solid fa-pen-to-square"></i>
                            </span>
                            <span>Edit Info</span>
                        </button>

                    </div>
                    {artworksView()}
                </div>
            }
            {showEditInfo &&
                <EditPlaylistInfoModal playlist={playlist} onClose={() => setShowEditInfo(false)} onRefresh={handleRefresh} />
            }
        </div >
    )
}

export default SinglePlaylist
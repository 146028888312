import './App.css';
import SideMenu from './component/SideMenu';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './page/Dashboard'
import Images from './page/Images'
import Artists from './page/Artists'
import HomeLayouts from './page/HomeLayouts'
import Playlists from './page/Playlists'
import SingleArtist from './page/SingleArtist';
import SinglePlaylist from './page/SinglePlaylist';
import { onAuthChange } from './module/firebase';
import SingleHomeLayout from './page/SingleHomeLayout';
import SingleHomeBlock from './page/SingleHomeBlock';
import SignIn from './page/SignIn';
import { ServerSettings } from './module/server/ServerSettings'
import { useState } from 'react';
import Loader from "react-js-loader"

function App() {

  const [loginState, setLoginState] = useState("")

  function renderRouting() {
    return (
      <BrowserRouter>
        <div className='columns'>
          <div className='column is-3'>
            <SideMenu />
          </div>
          <div className='column'>
            <Routes>
              <Route path='/' element={<Dashboard />} />
              <Route path='/images' element={<Images />} />
              <Route path='/playlists' element={<Playlists />} />
              <Route path='/playlists/:playlistId' element={<SinglePlaylist />} />
              <Route path='/artists' element={<Artists />} />
              <Route path='/artists/:artistId' element={<SingleArtist />} />
              <Route path='/home-layouts' element={<HomeLayouts />} />
              <Route path='/home-layouts/:layoutId' element={<SingleHomeLayout />} />
              <Route path='/home-blocks/:blockId' element={<SingleHomeBlock />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    )
  }
  onAuthChange((user) => {
    if (user) {
      ServerSettings.adminUsers()
        .then(users => {
          if (users && users.includes(user.uid)) {
            setLoginState("authorized")
          }
        })

    } else {
      setLoginState("unauthorized")
    }
    // console.log(user)
  })

  function render() {
    console.log(loginState)
    switch (loginState) {
      case "authorized":
        return renderRouting()
      case "unauthorized":
        return (<SignIn />)
      default: return (<div className='m-6 p-6'><Loader bgColor={"#ccc"} size={100} /></div>)
    }
  }

  return (
    <div className="App">
      {render()}
    </div>
  );
}

export default App;

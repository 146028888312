import React, { Fragment, useEffect, useState } from 'react'
import { Playlist } from '../../module/server/Playlist'
import { Link } from 'react-router-dom'

function GroupBlock(props) {
    const [playlists, setPlaylists] = useState([])
    const block = props.block

    useEffect(() => {
        (async () => {
            try {
                let fetchedPlaylist = []
                for (const playlist of block.playlists) {
                    const fetched = await Playlist.playlist(playlist)
                    fetchedPlaylist.push(fetched)
                }
                setPlaylists(fetchedPlaylist)
            } catch (e) {
                alert(e)
            }
        })()
    }, [block])

    return (
        <Fragment>
            {playlists &&
                <div className="columns is-multiline">
                    {playlists.map(playlist => (
                        <div key={playlist.id} className="column is-6">
                            <Link to={"/playlists/" + playlist.id}>
                                <figure className="image">
                                    <img className='has-background-color' src={playlist.coverImage && playlist.coverImage.thumbnailUrl} width={playlist.coverImage.thumbnailWidth} height={playlist.coverImage.thumbnailHeight} alt="" />
                                </figure>
                                <span className="is-size-5 has-text-weight-bold">{playlist.meta.title}</span>
                            </Link>
                        </div>
                    ))}
                </div>
            }
        </Fragment>
    )
}

export default GroupBlock
import React, { Fragment, useEffect, useState } from 'react'
import Loader from "react-js-loader"
import { useNavigate, useParams } from 'react-router-dom'
import AddNewArtistModal from '../component/artists/AddNewArtistModal.js'
import ArtworkModal from '../component/ArtworkModal.js'
import EditArtworkModal from '../component/EditArtworkModal.js'
import AddToPlaylistModal from '../component/singleArtist/AddToPlaylistModal.js'
import ArtworksButtons from '../component/singleArtist/ArtworksButtons.js'
import CreatePlaylist from '../component/singleArtist/CreatePlaylist.js'
import MetaView from '../component/singleArtist/MetaView.js'
import UploadImagesModal from '../component/singleArtist/UploadImagesModal'
import { Artist } from '../module/server/Artist.js'


function SingleArtist(props) {

    const [artist, setArtist] = useState(null);
    const [artworks, setArtworks] = useState([])
    const [chosenFiles, setChosenFiles] = useState([])
    const [showAddArtworks, setShowAddArtworks] = useState(false)
    const [showCreatePlaylist, setShowCreatePlaylist] = useState(false)
    const [selectedArtworkIds, setSelectedArtworkIds] = useState([])
    const [deletingArtworkIds, setDeletingArtworkIds] = useState([])
    const [isArtoworkDeleting, setIsArtoworkDeleting] = useState(false)
    const [isEditingInfo, setIsEditingInfo] = useState(false)
    const [fullscreenArtwork, setFullscreenArtwork] = useState(null)
    const [editedArtwork, setEditedArtwork] = useState(null)
    const [showAddToPlaylist, setShowAddToPlaylist] = useState(false)

    const { artistId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const artist = await Artist.artist(artistId)
                setArtist(artist)
                const artworks = await Artist.artworks(artistId)
                setArtworks(artworks)
            } catch (e) {
                alert(e)
            }
        })()
    }, [artistId]);

    useEffect(() => {
        if (!isArtoworkDeleting) { return }

        let toDeleteIds = deletingArtworkIds.slice()

        if (toDeleteIds.length > 0) {
            let id = toDeleteIds.pop()
            Artist.deleteArtwork(id)
                .then((id) => {
                    let deletingIds = deletingArtworkIds.filter(e => e !== id)
                    setDeletingArtworkIds(deletingIds)

                    let newArtworks = artworks.filter(e => e.id !== id)
                    setArtworks(newArtworks)
                })
                .catch(e => alert(e))
        } else {
            setIsArtoworkDeleting(false)
        }
    }, [deletingArtworkIds, isArtoworkDeleting, artworks]);

    const refreshImages = () => {
        Artist.artworks(artistId)
            .then(e => setArtworks(e))
            .catch(e => alert(e))
    }

    const handleChooseImageEvent = e => {
        let files = Array.prototype.slice.call(e.target.files)
        setChosenFiles(files)
        setShowAddArtworks(true)
    }

    function onArtworksModalClose() {
        setShowAddArtworks(false)
        setChosenFiles([])
    }

    function onArtworkClick(media) {
        if (deletingArtworkIds.includes(media.id)) {
            return
        }

        let selectedIds = Array.from(selectedArtworkIds)
        if (selectedIds.includes(media.id)) {
            selectedIds = selectedIds.filter(e => e !== media.id)
        } else {
            selectedIds.push(media.id)
        }

        setSelectedArtworkIds(selectedIds)
    }

    function deleteArtworks() {
        if (window.confirm("Permanently delete " + selectedArtworkIds.length + " artworks?")) {
            setDeletingArtworkIds(selectedArtworkIds.slice())
            setSelectedArtworkIds([])
            setIsArtoworkDeleting(true)
        }
    }

    function selectAllArtworks() {
        setSelectedArtworkIds(artworks.map(e => e.id))
    }

    function handleViewArtwork(artwork) {
        setFullscreenArtwork(artwork)
    }

    const titleView = () => (
        <Fragment>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <button className="button" onClick={() => navigate(-1)}>
                            <span className="icon">
                                <i className="fas fa-arrow-left"></i>
                            </span>
                            <span>Back</span>
                        </button>
                    </div>
                    <div className="level-item">
                        <p className='title'>{artist.name}</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )

    const personalPhotosView = () => (
        <div className="block">
            <span className='is-size-6 has-text-weight-bold'>Personal Photos</span>
        </div>
    )

    const artworksView = () => (
        <div className="block">
            <span className='is-size-6 has-text-weight-bold'>Artworks</span>
            <ArtworksButtons
                onChooseImages={handleChooseImageEvent}
                onDeselect={() => setSelectedArtworkIds([])}
                onCreatePlaylist={() => setShowCreatePlaylist(true)}
                onAddToPlaylist={() => setShowAddToPlaylist(true)}
                onDelete={deleteArtworks}
                isDeleting={isArtoworkDeleting}
                onSelectAll={selectAllArtworks}
                hasSelected={selectedArtworkIds.length > 0}
            />
            <div className="columns is-vcentered is-multiline">
                {artworks.map(artwork => (
                    <div className="column is-3" key={artwork.id}>
                        <figure className="block is-artwork-preview">
                            <img
                                draggable={false}
                                className={
                                    "has-background-color is-clickable " + (selectedArtworkIds.includes(artwork.id) && " is-selected ") + (deletingArtworkIds.includes(artwork.id) && " is-deleting")
                                } alt=""
                                width={artwork.thumbnailWidth}
                                height={artwork.thumbnailHeight}
                                src={artwork.thumbnailUrl}
                                onClick={e => onArtworkClick(artwork)}
                            />
                        </figure>
                        <div className="field is-grouped is-grouped-centered">
                            <p className="control">
                                <button onClick={() => setEditedArtwork(artwork)} className="button is-light ">
                                    <span className="icon">
                                        <i className="fa-solid fa-pen-to-square"></i>
                                    </span>

                                </button>
                            </p>
                            <p className="control">
                                <button onClick={() => handleViewArtwork(artwork)} className="button is-light ">
                                    <span className="icon">
                                        <i className="fa-solid fa-eye"></i>
                                    </span>
                                </button>
                            </p>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    )


    function handleEditMeta() {
        setIsEditingInfo(true)
    }

    function handleRefreshInfo(inArtist) {
        setArtist(inArtist)
    }

    return (
        <div className='section'>
            {artist === null
                ?
                <div className='container' style={{ marginTop: 100 }}>
                    <Loader bgColor={"#ccc"} size={100} />
                </div>
                :
                <div className='container'>
                    {titleView()}
                    <div className="box">
                        <MetaView
                            data={artist.meta}
                            onEdit={handleEditMeta}
                        />
                    </div>
                    {personalPhotosView()}

                    {artworksView()}
                </div>
            }
            {showAddArtworks === true &&
                <UploadImagesModal
                    artistId={artistId}
                    files={chosenFiles}
                    onRefresh={refreshImages}
                    onCloseClick={onArtworksModalClose} />}
            {showCreatePlaylist === true &&
                <CreatePlaylist
                    artworks={artworks.filter(a => selectedArtworkIds.includes(a.id))}
                    artistName={artist.name}
                    onClose={() => setShowCreatePlaylist(false)}
                />
            }
            {isEditingInfo &&
                <AddNewArtistModal artist={artist} onRefresh={handleRefreshInfo} onClose={() => setIsEditingInfo(false)} />
            }
            {fullscreenArtwork &&
                <ArtworkModal artwork={fullscreenArtwork} onClose={() => setFullscreenArtwork(null)} />
            }
            {editedArtwork &&
                <EditArtworkModal artwork={editedArtwork} onClose={() => setEditedArtwork(null)} />
            }
            {showAddToPlaylist &&
                <AddToPlaylistModal artworkIds={selectedArtworkIds} onClose={() => setShowAddToPlaylist(false)} />
            }
        </div >
    )
}

export default SingleArtist
import React, { Fragment } from 'react'

function ArtworksButtons(props) {
    const onChooseImages = props.onChooseImages
    const onDeselect = props.onDeselect
    const onCreatePlaylist = props.onCreatePlaylist
    const onAddToPlaylist = props.onAddToPlaylist
    const onDelete = props.onDelete
    const isDeleting = props.isDeleting
    const onSelectAll = props.onSelectAll
    const hasSelected = props.hasSelected

    return (
        <div className="level">
            <div className="level-left">
                <div className="level-item">
                    <div className="file is-light">
                        <label className="file-label">
                            <input className="file-input" type="file" multiple accept='.png, .jpg, .jpeg' onChange={onChooseImages} />
                            <span className="file-cta">
                                <span className="file-icon">
                                    <i className="fas fa-upload"></i>
                                </span>
                                <span className="file-label">
                                    Upload Images
                                </span>
                            </span>
                        </label>
                    </div>
                </div>

                {hasSelected &&
                    <Fragment>
                        <div className="level-item">
                            <button className="button is-light" onClick={onDeselect}>Deselect</button>
                        </div>

                        <div className="level-item">
                            <button className="button is-light" onClick={onCreatePlaylist}>Create playlist</button>
                        </div>

                        <div className="level-item">
                            <button className="button is-light" onClick={onAddToPlaylist}>Add to playlist</button>
                        </div>

                        <div className="level-item">
                            <button className={"button is-danger is-outlined " + (isDeleting && "is-loading")} onClick={onDelete}>Delete</button>
                        </div>
                    </Fragment>
                }
            </div>
            <div className="level-right">
                <div className="level-item">
                    <button className="button is-light" onClick={onSelectAll}>Select All</button>
                </div>
            </div>
        </div>
    )
}

export default ArtworksButtons
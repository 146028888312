import React from 'react'
import { useState } from 'react'
import { Playlist } from '../../module/server/Playlist'

function VisibilityButtons(props) {
    const playlist = props.playlist
    const [isPublished, setIsPublished] = useState(playlist.isPublished ?? false)
    const [isLoading, setIsLoading] = useState(false)

    function handleClick() {
        setIsLoading(true)

        if (isPublished === true) {
            setIsPublished(false)
            Playlist.published(playlist.id, false)
                .finally(() => setIsLoading(false))
        } else {
            setIsPublished(true)
            Playlist.published(playlist.id, true)
                .finally(() => setIsLoading(false))
        }
    }

    return (
        <button className={"button " + (isLoading ? " is-loading" : "")} onClick={handleClick} >{isPublished ? "Published" : "Unpublished"}</button>
    )
}

export default VisibilityButtons
import React, { Fragment, useEffect, useState } from 'react'
import { Playlist } from '../../module/server/Playlist'
import Loader from "react-js-loader"

function SelectPlaylistModal(props) {

    const [matchedPlaylists, setMatchedPlaylists] = useState([])
    const [playlists, setPlaylists] = useState([])
    const [selectedIds, setSelectedIds] = useState(props.selectedIds ?? [])
    const allowMultipleSelection = props.allowMultipleSelection
    const onAddPlaylists = props.onAddPlaylists

    useEffect(() => {
        Playlist.all()
            .then(e => {
                setPlaylists(e)
                setMatchedPlaylists(e)
            })
            .catch(e => alert(e))
    }, [setPlaylists])

    const handleClose = () => {
        props.onClose()
    }

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase()
        if (e.target.value === "") {
            setMatchedPlaylists(playlists)
        } else {
            const result = playlists.filter(e => e.meta.title.toLowerCase().includes(query))
            setMatchedPlaylists(result)
        }

    }

    const handlePlaylistClick = (id) => {
        if (allowMultipleSelection) {
            let ids = selectedIds.slice()
            if (ids.includes(id)) {
                ids = ids.filter(e => e !== id)
            } else {
                ids.push(id)
            }
            setSelectedIds(ids)
        } else {
            onAddPlaylists(playlists.filter(e => e.id === id))
            handleClose()
        }
    }

    const handleAddSelected = () => {
        onAddPlaylists(playlists.filter(e => selectedIds.includes(e.id)))
        handleClose()
    }

    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={handleClose}></div>
            <div className="modal-content box" >
                <div className="block">
                    {playlists.length > 0 ?
                        <Fragment>
                            <p className="subtitle has-text-centered">Select Playlist</p>
                            <div className="field">
                                <p className="control is-expanded has-icons-left">
                                    <input className="input" type="text" placeholder="Search" onChange={handleSearch} />
                                    <span className="icon is-small is-left">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </span>
                                </p>
                            </div>
                        </Fragment>
                        :
                        <Loader bgColor={"#ccc"} size={50} />
                    }
                </div>
                <div className="block">
                    <ul className="menu-list">
                        {matchedPlaylists.map(p => (<li key={p.id}><button className={"is-fullwidth button " + (selectedIds.includes(p.id) ? "is-info" : "is-dark is-inverted")} onClick={() => handlePlaylistClick(p.id)}>{p.meta.title}</button></li>))}
                    </ul>
                </div>
                {allowMultipleSelection &&
                    <div className="block">
                        <button className="button is-primary" onClick={handleAddSelected}>Add Selected</button>
                    </div>
                }
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={handleClose}></button>
        </div >
    )
}

export default SelectPlaylistModal
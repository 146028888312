// const resizeID = require('resize-image-data')

export async function imageDataFromFile(file, compression = 0.7) {
    const image = new Image()
    image.src = URL.createObjectURL(file)
    return new Promise((resolve, reject) => {
        image.onload = () => {
            const width = image.width
            const height = image.height
            const canvas = document.createElement('canvas')
            canvas.width = width
            canvas.height = height
            const context = canvas.getContext('2d')
            context.drawImage(image, 0, 0)

            canvas.toBlob((blob) => {
                resolve({
                    imageData: context.getImageData(0, 0, width, height),
                    blob: blob,
                    image: image
                }, "image/jpeg", compression)
            })
        }
    })
}

export async function resizeImageDataUrl(image, maxWidth, maxHeight, compression = 0.7) {
    const widthRatio = maxWidth / image.width
    const heightRatio = maxHeight / image.height
    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio
    const newWidth = image.width * ratio
    const newHeight = image.height * ratio

    const canvas = document.createElement('canvas')
    canvas.width = newWidth
    canvas.height = newHeight
    const context = canvas.getContext('2d')
    context.imageSmoothingQuality = "high"

    context.drawImage(image, 0, 0, newWidth, newHeight);

    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            resolve({
                imageData: context.getImageData(0, 0, newWidth, newHeight),
                blob: blob,
            }, "image/jpeg", compression)
        })
    })
}

import React, { Fragment, useEffect, useState } from 'react'
import { Playlist } from '../../module/server/Playlist'
import Loader from "react-js-loader"

function AddToPlaylistModal(props) {
    const [playlists, setPlaylists] = useState([])
    const [matchedPlaylists, setMatchedPlaylists] = useState([])
    const [selectedId, setSelectedId] = useState(null)

    const artworkIds = props.artworkIds

    useEffect(() => {
        Playlist.all()
            .then(e => {
                console.log(props.artworkIds)
                setPlaylists(e)
                setMatchedPlaylists(e)
            })
            .catch(e => alert(e))
    }, [setPlaylists, props.artworkIds])

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase()
        if (e.target.value === "") {
            setMatchedPlaylists(playlists)
        } else {
            const result = playlists.filter(e => e.meta.title.toLowerCase().includes(query))
            setMatchedPlaylists(result)
        }

    }

    const handlePlaylistClick = (id) => {
        setSelectedId(id)

        Playlist.addArtworks(id, artworkIds)
            .then(_ => handleClose())
            .catch(e => alert(e))
            .finally(_ => setSelectedId(null))
    }

    const handleClose = () => {
        props.onClose()
    }

    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={handleClose}></div>
            <div className="modal-content box" >
                <div className="block">
                    {playlists.length > 0 ?
                        <Fragment>
                            <p className="subtitle has-text-centered">Select Playlist</p>
                            <div className="field">
                                <p className="control is-expanded has-icons-left">
                                    <input className="input" type="text" placeholder="Search" onChange={handleSearch} />
                                    <span className="icon is-small is-left">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </span>
                                </p>
                            </div>
                        </Fragment>
                        :
                        <Loader bgColor={"#ccc"} size={50} />
                    }
                </div>
                <div className="block">
                    <ul className="menu-list">
                        {matchedPlaylists.map(p => (<li key={p.id}><button className={"button is-fullwidth " + (selectedId === p.id ? "is-light is-loading" : "is-dark is-inverted")} onClick={() => handlePlaylistClick(p.id)}>{p.meta.title}</button></li>))}
                    </ul>
                </div>
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={handleClose}></button>
        </div >
    )
}

export default AddToPlaylistModal
import React, { useState } from 'react'
import { Artwork } from '../module/server/Artwork'

function EditArtworkModal(props) {
    const artwork = props.artwork
    const [title, setTitle] = useState(artwork.meta.title ?? "")
    const [description, setDescription] = useState(artwork.meta.description ?? "")
    const [tags, setTags] = useState(artwork.meta.tags ?? [])
    const [isSaving, setIsSaving] = useState(false)


    function handleDeleteTag(tag) {
        setTags(tags.filter(e => e !== tag))
    }

    function handleTagsKeyDown(e) {
        if (e.key === "Enter") {
            const inTags = e.target.value.split(",").map(e => e.trim())
            e.target.value = ""

            setTags(tags.concat(inTags))
        }
    }

    function handleSave() {
        setIsSaving(true)
        Artwork.updateMeta(artwork.id, {
            title: title,
            description: description,
            tags: tags
        })
            .then(props.onClose)
            .catch(e => alert(e))
            .finally(() => setIsSaving(false))

    }

    return (
        < div className='modal is-active' >
            <div className='modal-background' onClick={props.onClose}></div>
            <div className='modal-card'>
                <header className='modal-card-head'>
                    <p className="modal-card-title">Edit Artwork</p>
                    <button className='delete' onClick={props.onClose}></button>
                </header>

                <section className='modal-card-body has-background-white'>

                    <article className='media'>
                        <figure className='media-left'>
                            <p className='image is-128x128'>
                                <img className='has-background-color' src={artwork.thumbnailUrl} width={artwork.thumbnailWidth} height={artwork.thumbnailHeight} alt='' />
                            </p>
                        </figure>

                        <div className='media-content'>
                            <div className='field'>
                                <div className='control'>
                                    <input className='input' type='text' placeholder='Title' value={title} onChange={(e) => setTitle(e.target.value)} />
                                </div>
                            </div>

                            <div className='field'>
                                <div className='control'>
                                    <textarea className='textarea' placeholder='Description' value={description} onChange={(e) => setDescription(e.target.value)} />
                                </div>
                            </div>
                            <div className="field is-grouped">
                                <div className='tags are-medium'>
                                    {tags && tags.map((e, i) => (
                                        <span key={e + i} className="tag">{e}<button className="delete is-small" onClick={() => handleDeleteTag(e)}></button></span>
                                    ))}

                                    <span className="field"><span className="control"><input className='input is-small' type="text" placeholder='Tags' onKeyDown={(e) => handleTagsKeyDown(e)} /></span></span>
                                </div>
                            </div>
                        </div>
                    </article>

                </section>

                <footer className='modal-card-foot'>
                    <button className={"button is-success" + (isSaving ? " is-loading" : "")} onClick={handleSave}>Save</button>
                </footer>
            </div>
        </div >
    )
}

export default EditArtworkModal


function Dashboard() {
    return (
        <div className='section'>
            <div className='container'>
                <h1 className='title'>Dashboard</h1>
            </div>
        </div>
    )
}

export default Dashboard
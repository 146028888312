// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBgkZ6O7Y_HOLTvq1iI7_fdXqJKFKuTggo",
    authDomain: "kreoz-1d7d3.firebaseapp.com",
    databaseURL: "https://kreoz-1d7d3.firebaseio.com",
    projectId: "kreoz-1d7d3",
    storageBucket: "kreoz-1d7d3.appspot.com",
    messagingSenderId: "1042089458455",
    appId: "1:1042089458455:web:883f782f2c530ac27e9552",
    measurementId: "G-VCNV8H7LX1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export function signOut() {
    getAuth().signOut()
}

export function onAuthChange(f) {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        f(user)
    });
}



async function checkAuth() {
    const auth = getAuth();
    if (auth.currentUser === null) {
        try {
            await signInAnonymously(auth)
        } catch (e) {
            alert("Error code: " + e.code + " Message: " + e.message)
        }
    }
}

export function splitArrayForQuery(inputArray, chunkSize = 10) {
    const result = inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize)

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, [])

    return result
}

export { db, checkAuth, storage }
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore"
import { deleteObject, getDownloadURL, ref, updateMetadata, uploadBytes } from "firebase/storage"
import { db, checkAuth, storage } from "../firebase"
import { v4 as uuid } from 'uuid';
import { flatSnapshot } from "./FirebaseUtils";
import { Playlist } from "./Playlist";
import { FIREBASE_ROOT_COLLECTION } from "../../env";
import { Artwork } from "./Artwork";
import { Image } from "./Image";

class Artist { }

Artist.collectionPath = FIREBASE_ROOT_COLLECTION + "artists"
const artistsCollection = Artist.collectionPath

Artist.all = async function () {
    try {
        await checkAuth()
        const snapshot = await getDocs(collection(db, artistsCollection))
        const result = snapshot.docs.map(input => flatSnapshot(input))
        return result
    } catch (err) {
        throw err
    }
}

Artist.new = async function (artist) {
    try {
        await checkAuth()

        const collectionRef = collection(db, artistsCollection)
        artist.createdAt = Date.now()
        const docRef = await addDoc(collectionRef, artist)
        return docRef.id
    } catch (err) {
        throw err
    }
}

Artist.delete = async function (id) {
    await checkAuth()

    const artworksQuery = query(collection(db, Artwork.collectionPath), where("artistId", "==", id))
    const artworkSnapshots = await getDocs(artworksQuery)
    for (const artwork of artworkSnapshots.docs) {
        await this.deleteArtwork(artwork.id)
    }

    let docRef = doc(db, artistsCollection, id)
    await deleteDoc(docRef)
}

Artist.artist = async function (id) {
    try {
        await checkAuth()

        let docRef = doc(db, artistsCollection, id)
        const docSnapshot = await getDoc(docRef)
        return flatSnapshot(docSnapshot)
    } catch (err) {
        throw err
    }
}

Artist.artworks = async function (id) {
    try {
        await checkAuth()

        const collectionRef = collection(db, Artwork.collectionPath)
        const q = query(collectionRef, where("artistId", "==", id))
        const snapshot = await getDocs(q)

        const result = snapshot.docs.map(input => flatSnapshot(input))

        return result
    } catch (err) {
        throw err
    }
}

Artist.uploadArtwork = async function (id, obj, imageBlob, thumbnailBlob) {
    try {
        await checkAuth()

        const imageId = uuid()
        const imagePath = Image.storagePath + "images/" + imageId + ".jpg"
        const thumbnailPath = Image.storagePath + "thumbnails/" + imageId + ".jpg"
        const imageRef = ref(storage, imagePath)
        const thumbRef = ref(storage, thumbnailPath)

        await uploadBytes(imageRef, imageBlob)
        await uploadBytes(thumbRef, thumbnailBlob)

        obj.imagePath = imagePath
        obj.thumbnailPath = thumbnailPath
        obj.imageUrl = await getDownloadURL(imageRef)
        obj.thumbnailUrl = await getDownloadURL(thumbRef)
        obj.createdAt = Date.now()
        obj.artistId = id

        const collectionRef = collection(db, Artwork.collectionPath)
        let snapshot = await addDoc(collectionRef, obj)

        await updateMetadata(thumbRef, { cacheControl: "public, max-age=10368000", contentType: "image/jpeg" })
        await updateMetadata(imageRef, { cacheControl: "public, max-age=10368000", contentType: "image/jpeg" })

        return snapshot.id
    } catch (err) {
        throw err
    }
}

Artist.deleteArtwork = async function (artworkId) {
    const imageSnapshot = await getDoc(doc(db, Artwork.collectionPath, artworkId))

    const image = flatSnapshot(imageSnapshot)

    try {
        await deleteObject(ref(storage, image.imagePath))
        await deleteObject(ref(storage, image.thumbnailPath))
        await deleteDoc(doc(db, Artwork.collectionPath, artworkId))
    } catch (e) { }
    await Playlist.removeArtwork(artworkId)

    return imageSnapshot.id

}

Artist.update = async function (artistId, artist) {
    await setDoc(doc(db, artistsCollection, artistId), {
        meta: artist.meta,
        name: artist.name
    }, { merge: true })
}

export { Artist }
import { useState, useEffect } from "react"
import BlocksList from "../component/home/BlocksList"
import CreateBlockModal from "../component/home/CreateBlockModal"
import CreateLayoutModal from "../component/home/CreateLayoutModal"
import LayoutsList from "../component/home/LayoutsList"
import { HomeBlock } from "../module/server/HomeBlock"
import { HomeLayout } from "../module/server/HomeLayout"

function HomeLayouts() {
    const [showCreateBlock, setShowCreateBlock] = useState(false)
    const [showCreateLayout, setShowCreateLayout] = useState(false)
    const [layouts, setLayouts] = useState([])
    const [blocks, setBlocks] = useState([])
    // const [artists, setArtists] = useState([])

    useEffect(() => {
        fetch()
        fetchBlocks()
    }, [])

    const fetch = () => {
        HomeLayout.all()
            .then(result => setLayouts(result))
            .catch(e => alert(e))
    }

    const fetchBlocks = () => {
        HomeBlock.all()
            .then(result => setBlocks(result))
            .catch(e => alert(e))
    }

    function handleBlockDeleted(block) {
        setBlocks(blocks.filter(e => e.id !== block.id))
    }

    return (
        <div className='section'>
            <div className='block'>
                <h1 className='title'>Home</h1>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <button className="button is-primary" onClick={() => setShowCreateBlock(true)}>New Block</button>
                        </div>
                        <div className="level-item">
                            <button className="button is-primary" onClick={() => setShowCreateLayout(true)}>New Layout</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container is-widescreen mt-5'>
                <div className="block">
                    <p className="subtitle">Layouts</p>
                    <LayoutsList onRefresh={fetch} layouts={layouts} />
                </div>
                <div className="block mt-6">
                    <p className="subtitle">Blocks</p>
                    <BlocksList onDeleted={handleBlockDeleted} onRefresh={fetchBlocks} blocks={blocks} />
                </div>
            </div>

            {showCreateLayout &&
                <CreateLayoutModal onRefresh={fetch} onClose={() => setShowCreateLayout(false)} />
            }

            {showCreateBlock &&
                <CreateBlockModal onRefresh={fetchBlocks} onClose={() => setShowCreateBlock(false)} />
            }
        </div>
    )
}

export default HomeLayouts
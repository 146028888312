import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import React, { useState } from 'react'

function SignIn() {

    const [isSigningIn, setIsSigningIn] = useState(false)
    let email
    let password

    function passwordKeyDown(e) {
        if (e.key === "Enter") {
            signIn()
        }
    }

    function signIn() {
        setIsSigningIn(true);

        signInWithEmailAndPassword(getAuth(), email, password)
            .catch(e => {
                setIsSigningIn(false);
                alert(e)
            })
        // .finally(setIsSigningIn(false))
    }

    return (
        <section className="section is-medium">
            <div className="columns">
                <div className="column"></div>
                <div className="column has-text-centered is-4">
                    <div className="box">
                        <div className="field">
                            <p className="control"><input type="text" className="input" placeholder='E-mail' onChange={(e) => email = e.target.value} /></p>
                        </div>
                        <div className="field">
                            <p className="control"><input type="password" className="input" placeholder='Password' onChange={(e) => password = e.target.value} onKeyDown={passwordKeyDown} /></p>
                        </div>
                        <div className="field">
                            <p className="control"><button className={"button is-primary " + (isSigningIn ? "is-loading" : "")} onClick={signIn}>Sign in</button></p>
                        </div>
                    </div>
                </div>
                <div className="column"></div>
            </div>
        </section>

    )
}

export default SignIn
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Playlist } from '../../module/server/Playlist.js';
import { formatDate } from "../../module/Utils"

function PlaylistsList(props) {
    const [deleteingId, setDeleteingId] = useState("")

    const navigate = useNavigate();

    const onDelete = (playlist) => {
        if (window.confirm("Delete " + playlist.title + "?")) {
            setDeleteingId(playlist.id)
            Playlist.delete(playlist.id)
                .then(() => {
                    let newPlaylists = props.playlists.slice().filter(e => e.id !== playlist.id);
                    props.updatePlaylists(newPlaylists);
                })
                .catch(e => alert(e))
        }
    };

    function visibilityTag(playlist) {
        if (playlist.isPublished === true) {
            return (<span className="tag medium is-primary">published</span>)
        } else {
            return (<span className="tag">unpublished</span>)
        }
    }

    function premiumTag(playlist) {
        if (playlist.isFree === true) {
            return (<span className="tag medium is-primary">free</span>)
        }
    }

    const list = () => (
        props.playlists.sort((a, b) => a.createdAt < b.createdAt).map(playlist => (
            <tr key={playlist.id} className="is-clickable" onClick={() => navigate(playlist.id)}>
                <td>
                    <figure className='image is-16by9'>
                        {playlist.coverImage &&
                            <img className='has-background-color' src={playlist.coverImage.thumbnailUrl} width={playlist.coverImage.thumbnailWidth} height={playlist.coverImage.thumbnailHeight} alt="" />
                        }
                    </figure>
                </td>
                <td>{playlist.meta.title}</td>
                <td>{premiumTag(playlist)}</td>
                <td>{visibilityTag(playlist)}</td>
                <td>{playlist.artworks.length}</td>
                <td>{formatDate(playlist.createdAt)}</td>
                <td>
                    <div className='field is-grouped is-grouped-right'>
                        <p className='control'>
                            <button className={"button is-danger " + (deleteingId === playlist.id ? "is-loading is-outlined" : "is-inverted")} onClick={e => { e.stopPropagation(); onDelete(playlist) }}>
                                <span className="icon is-small">
                                    <i className="fas fa-times"></i>
                                </span>
                                <span>Delete</span>
                            </button>
                        </p>
                    </div>
                </td>
            </tr >
        ))
    );

    return (
        <table className='table is-hoverable is-fullwidth'>
            <thead>
                <tr>
                    <th>Cover</th>
                    <th>Name</th>
                    <th>Free</th>
                    <th>Published</th>
                    <th>Artworks</th>
                    <th>Created</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>

                {list()}

            </tbody>
        </table>
    )
}

export default PlaylistsList
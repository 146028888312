import React, { useState } from 'react'
import { imageDataFromFile, resizeImageDataUrl } from '../../module/ImageUtils'
import { Playlist } from '../../module/server/Playlist'

function CreatePlaylist(props) {

    const artworks = props.artworks ?? []
    const artistName = props.artistName ?? ""
    const onClose = props.onClose

    const [isUploading, setIsUploading] = useState(false)
    const [dragId, setDragId] = useState(undefined)
    const [selectedCoverImage, setSelectedCoverImage] = useState(undefined)
    const [palylistName, setPalylistName] = useState(artistName)
    const [playlistDescription, setPlaylistDescription] = useState("")
    const [isPublished, setIsPublished] = useState(false)
    const [artworksOrdered, setArtworksOrdered] = useState(
        artworks.map((e, i) => (
            { artwork: e, order: i + 1 }
        ))
    )

    function handleClose() {
        onClose()
    }

    function onDragStart(e) {
        setDragId(e.target.id)
    }

    function onDrop(e) {
        let newArtworksOrdered = artworksOrdered.slice()
        const targetId = e.target.closest(".is-artwork-preview").id
        const targetIndex = newArtworksOrdered.findIndex(e => e.artwork.id === targetId)
        const sourceIndex = newArtworksOrdered.findIndex(e => e.artwork.id === dragId)

        if (targetIndex === 0) {
            newArtworksOrdered[sourceIndex].order = newArtworksOrdered[targetIndex].order / 2
        } else if (Math.abs(targetIndex - sourceIndex) === 1) {
            const order = newArtworksOrdered[sourceIndex].order
            newArtworksOrdered[sourceIndex].order = newArtworksOrdered[targetIndex].order
            newArtworksOrdered[targetIndex].order = order
        } else {
            if (targetIndex < sourceIndex) {
                newArtworksOrdered[sourceIndex].order = (newArtworksOrdered[targetIndex].order + newArtworksOrdered[targetIndex - 1].order) / 2
            } else {
                newArtworksOrdered[sourceIndex].order = (newArtworksOrdered[targetIndex].order + newArtworksOrdered[targetIndex + 1].order) / 2
            }
        }

        setArtworksOrdered(newArtworksOrdered)
    }

    function createPlaylist() {
        const playlist = {
            meta: {
                title: palylistName,
                description: playlistDescription,
            },
            artworks: artworksOrdered.map(e => e.artwork.id),
            artworksOrder: artworksOrdered.map(e => ({ id: e.artwork.id, order: e.order })),
            isPublished: isPublished
        };

        (async () => {
            try {
                setIsUploading(true)

                var coverImageData
                if (selectedCoverImage !== undefined) {

                    const mainImage = await imageDataFromFile(selectedCoverImage);
                    const thumbnailImageData = await resizeImageDataUrl(mainImage.image, 500, 500)

                    coverImageData = {
                        imageBlob: mainImage.blob,
                        imageWidth: mainImage.imageData.width,
                        imageHeight: mainImage.imageData.height,
                        thumbnailBlob: thumbnailImageData.blob,
                        thumbnailWidth: thumbnailImageData.imageData.width,
                        thumbnailHeight: thumbnailImageData.imageData.height
                    }
                }

                await Playlist.new(playlist, coverImageData)

                if (props.onRefresh) { props.onRefresh() }
                handleClose()
            } catch (e) {
                alert(e)
            } finally {
                setIsUploading(false)
            }
        })()
    }

    return (
        <div className='modal is-active'>
            <div className="modal-background" onClick={handleClose} />
            <div className="modal-card">

                {/* Header */}
                <header className="modal-card-head">
                    <span className="modal-card-title">Create playlist</span>
                    <button className="delete" onClick={handleClose} />
                </header>


                <section className="modal-card-body has-background-white">
                    {/* Title */}
                    <div className="block">
                        <div className="field">
                            <p className="control is-expanded">
                                <input placeholder='Name' type="text" className="input" defaultValue={artistName} onChange={(e => setPalylistName(e.target.value))} />
                            </p>
                        </div>

                        {/* Description */}
                        <div className="field">
                            <p className="control is-expanded">
                                <textarea placeholder='Description' className="textarea" onChange={(e => setPlaylistDescription(e.target.value))}></textarea>
                            </p>
                        </div>

                        {/* Visibility */}
                        <div className="field is-grouped">
                            <label htmlFor="" className="label">Visibility:</label>
                            <p className="control">
                                <label className="checkbox px-2"><input type="checkbox" defaultChecked={isPublished} onChange={(e => setIsPublished(e.target.checked))} /><span className="px-1">Public</span></label>
                            </p>
                        </div>
                    </div>

                    {/* Cover Photo */}
                    <div className="block">

                        <div className="field is-grouped">
                            {/* Upload Button */}
                            <div className="control">
                                <div className="file is-light">
                                    <label className="file-label">
                                        <input className="file-input" type="file" multiple accept='.png, .jpg, .jpeg' onChange={(e => setSelectedCoverImage(e.target.files[0]))} />
                                        <span className="file-cta">
                                            <span className="file-icon">
                                                <i className="fas fa-upload"></i>
                                            </span>
                                            <span className="file-label">
                                                Upload Cover
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            {/* Delete Button */}
                            {
                                selectedCoverImage !== undefined &&
                                <div className="control">
                                    <button className="button is-danger is-outlined" onClick={(e => setSelectedCoverImage(undefined))}>Delete</button>
                                </div>
                            }
                        </div>

                        {/* Cover Photo Preview */}
                        <div className='block columns'>
                            <div className="column is-6">
                                {
                                    selectedCoverImage !== undefined &&
                                    <img className='has-background-color' src={URL.createObjectURL(selectedCoverImage)} alt='' />
                                }
                            </div>
                        </div>
                    </div>

                    {/* Selected Artworks */}
                    {artworks.length > 0 &&
                        <div className="block">
                            <p className="subtitle has-text-weight-bold">Selected Artworks<span className='has-text-weight-normal ml-1 is-size-6'>(Drag to Reorder)</span></p>
                            <div className="columns is-multiline">
                                {artworksOrdered.sort((a, b) => a.order > b.order).map((e, i) => (
                                    <div key={e.artwork.id} className="column is-4">
                                        <figure id={e.artwork.id} className="is-artwork-preview is-clickable" onDragOver={(ev) => ev.preventDefault()} draggable={true} onDrop={onDrop} onDragStart={onDragStart}>
                                            <img className='has-background-color ' draggable={false} src={e.artwork.thumbnailUrl} alt="" />
                                        </figure>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }

                </section>

                {/* Footer */}
                <footer className="modal-card-foot">
                    <button className={"button is-success " + (isUploading && "is-loading")} onClick={createPlaylist}>Create</button>
                </footer>

            </div>
        </div>
    )
}

export default CreatePlaylist
import { useEffect, useState } from 'react'
import AddNewArtistModal from '../component/artists/AddNewArtistModal.js';
import ArtistsList from '../component/artists/ArtistsList.js';
import { Artist } from '../module/server/Artist.js';

function Artists() {
    const [showAddArtist, setShowAddArtist] = useState(false)
    const [artists, setArtists] = useState([])
    const [filteredArtists, setFilteredArtists] = useState([])
    const [searchQuery, setSearchQuery] = useState("")

    useEffect(() => {
        Artist.all()
            .then(result => {
                setArtists(result)
            })
            .catch(err => alert(err))
    }, [])

    useEffect(() => {
        const queries = searchQuery.split(" ").map(e => e.trim().toLocaleLowerCase()).filter(e => e !== "")
        if (queries.length === 0) {
            setFilteredArtists(artists)
            return;
        }

        let allFiltered = []
        for (const query of queries) {
            const filtered = artists.filter(e => {
                const tags = e.meta.tags ? e.meta.tags.map(e => e.toLowerCase()) : []
                const name = e.name.toLowerCase()
                const country = e.meta.country ? e.meta.country.toLowerCase() : ""
                return (
                    name.includes(query) || tags.findIndex(a => a.includes(query)) > -1 || country.includes(query)
                )
            })



            allFiltered = allFiltered.concat(filtered)
        }

        allFiltered = allFiltered.filter((e, i, self) => self.indexOf(e) === i);

        setFilteredArtists(allFiltered)
    }, [artists, searchQuery])

    const refresh = () => {
        Artist.all()
            .then(result => setArtists(result))
            .catch(err => alert(err))
    }


    return (
        <div className='section'>
            <div className='block'>
                <h1 className='title'>Artists</h1>
                <button className='button is-primary' onClick={() => setShowAddArtist(true)}>Add</button>
            </div>
            <div className="block">
                <input type="text" className="input" placeholder='Filter artist' onChange={e => setSearchQuery(e.target.value)} />
            </div>

            <div className='container is-widescreen mt-5'>
                <ArtistsList onRefresh={refresh} artists={filteredArtists} />
            </div>

            {showAddArtist &&
                <AddNewArtistModal onRefresh={refresh} onClose={() => setShowAddArtist(false)} />
            }
        </div>
    )
}

export default Artists
import { doc, setDoc } from "firebase/firestore"
import { FIREBASE_ROOT_COLLECTION } from "../../env"
import { db } from "../firebase"

class Artwork { }

Artwork.collectionPath = FIREBASE_ROOT_COLLECTION + "artworks"
const collectionPath = Artwork.collectionPath

Artwork.updateMeta = async function (artworkId, meta) {
    await setDoc(doc(db, collectionPath, artworkId), { meta: meta }, { merge: true })
}

export { Artwork }
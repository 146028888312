import React, { useState } from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { HomeLayout } from '../../module/server/HomeLayout.js';
import { formatDate } from '../../module/Utils.js';

function LayoutsList(props) {

    const [deleteingId, setDeleteingId] = useState("")
    const [makingActiveId, setMakingActiveId] = useState(null)
    const [layouts, setLayouts] = useState([])

    const navigate = useNavigate();

    useEffect(() => {
        setLayouts(props.layouts)
    }, [props.layouts])

    const handleDelete = (layout) => {
        if (window.confirm("Delete " + layout.name + "?")) {
            setDeleteingId(layout.id)
            HomeLayout.delete(layout.id)
                .then(() => {
                    props.onRefresh()
                })
                .catch(e => alert(e))
        }
    };

    const handleActive = (layout) => {
        setMakingActiveId(layout.id)
        HomeLayout.makeActive(layout.id)
            .then(res => {
                let l = layouts.slice()
                const oldActive = l.find(e => e.id === res.oldActiveId)
                if (oldActive !== undefined) { oldActive.isActive = false }
                l.find(e => e.id === res.newActiveId).isActive = true
                setLayouts(l)
            })
            .catch(e => alert(e))
            .finally(() => setMakingActiveId(false))
    }

    return (
        <table className='table is-hoverable is-fullwidth'>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Active</th>
                    <th>Updated</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    layouts.sort((a, b) => a.createdAt < b.createdAt).map(layout => (
                        <tr key={layout.id} className="is-clickable" onClick={() => navigate(layout.id)}>
                            <td>{layout.name}</td>
                            <td>
                                {layout.isActive &&
                                    <span className="tag is-medium is-primary">active</span>
                                }

                            </td>
                            <td>{formatDate(layout.updatedAt)}</td>
                            <td>
                                <div className='field is-grouped is-grouped-right'>
                                    {!layout.isActive &&
                                        <p className='control'>
                                            <button className={"button is-dark " + (makingActiveId === layout.id ? "is-loading is-outlined" : "is-inverted")} onClick={e => { e.stopPropagation(); handleActive(layout) }}>
                                                <span className="icon is-small">
                                                    <i className="fa-solid fa-power-off"></i>
                                                </span>
                                                <span>Make Active</span>
                                            </button>
                                        </p>
                                    }
                                    <p className='control'>
                                        <button className={"button is-danger " + (deleteingId === layout.id ? "is-loading is-outlined" : "is-inverted")} onClick={e => { e.stopPropagation(); handleDelete(layout) }}>
                                            <span className="icon is-small">
                                                <i className="fas fa-times"></i>
                                            </span>
                                            <span>Delete</span>
                                        </button>
                                    </p>
                                </div>
                            </td>
                        </tr >
                    ))
                }

            </tbody>
        </table >
    )
}

export default LayoutsList
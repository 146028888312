import React, { Fragment, useEffect, useState } from 'react'
import Loader from "react-js-loader"
import { HomeBlock } from '../../module/server/HomeBlock'

function SelectBlockModal(props) {
    const [matchedBlocks, setMatchedBlocks] = useState([])
    const [blocks, setBlocks] = useState([])
    const [selectedIds, setSelectedIds] = useState([])
    const allowMultipleSelection = props.allowMultipleSelection

    const onAddBlocks = props.onAddBlocks
    const onClose = props.onClose
    const inBlocks = props.blocks

    useEffect(() => {
        HomeBlock.all()
            .then(e => {
                setBlocks(e)
                setMatchedBlocks(e)
                setSelectedIds(inBlocks ?? [])
            })
            .catch(e => alert(e))
    }, [setBlocks, inBlocks])

    const handleClose = () => {
        onClose()
    }

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase()
        if (e.target.value === "") {
            setMatchedBlocks(blocks)
        } else {
            const result = blocks.filter(e => e.meta.title.toLowerCase().includes(query))
            setMatchedBlocks(result)
        }

    }

    const handleBlockClick = (id) => {
        if (allowMultipleSelection) {
            let ids = selectedIds.slice()
            if (ids.includes(id)) {
                ids = ids.filter(e => e !== id)
            } else {
                ids.push(id)
            }
            setSelectedIds(ids)
        } else {
            onAddBlocks(blocks.filter(e => e.id === id))
            handleClose()
        }
    }

    const handleAddSelected = () => {
        onAddBlocks(blocks.filter(e => selectedIds.includes(e.id)))
        handleClose()
    }

    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={handleClose}></div>
            <div className="modal-content box" >
                <div className="block">
                    {blocks.length > 0 ?
                        <Fragment>
                            <p className="subtitle has-text-centered">Select Blocks</p>
                            <div className="field">
                                <p className="control is-expanded has-icons-left">
                                    <input className="input" type="text" placeholder="Search" onChange={handleSearch} />
                                    <span className="icon is-small is-left">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </span>
                                </p>
                            </div>
                        </Fragment>
                        :
                        <Loader bgColor={"#ccc"} size={50} />
                    }
                </div>
                <div className="block">
                    <ul className="menu-list">
                        {matchedBlocks.map(p => (<li key={p.id}>
                            <button className={"is-fullwidth button " + (selectedIds.includes(p.id) ? "is-link" : " is-inverted is-dark")} onClick={() => handleBlockClick(p.id)}>
                                {p.meta.title}<span className='ml-1 is-size-7'> {"(" + p.type + ")"}</span>
                            </button>
                        </li>))}
                    </ul>
                </div>
                {allowMultipleSelection &&
                    <div className="block">
                        <button className="button is-primary" onClick={handleAddSelected}>Add Selected</button>
                    </div>
                }
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={handleClose}></button>
        </div >
    )
}

export default SelectBlockModal
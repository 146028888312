import React, { useState } from 'react'
import { imageDataFromFile, resizeImageDataUrl } from '../module/ImageUtils'
import { Playlist } from '../module/server/Playlist'

function EditPlaylistInfoModal(props) {
    const playlist = props.playlist
    const [title, setTitle] = useState(playlist.meta.title ?? "")
    const [coverImage, setCoverImage] = useState(playlist.coverImage ?? null)
    const [description, setDescription] = useState(playlist.meta.description ?? "")
    const [isSaving, setIsSaving] = useState(false)
    const [newCoverImage, setNewCoverImage] = useState(null)
    const [errorMessages, setErrorMessages] = useState([])

    function handleSave() {
        setErrorMessages([])
        let errorMessages = []
        if (coverImage === null && newCoverImage === null) {
            errorMessages.push("Pleaase select a cover image")
        }

        if (title.length === 0) {
            errorMessages.push("Title cannot be empty")
        }

        if (errorMessages.length > 0) {
            setErrorMessages(errorMessages)
            return
        }

        setIsSaving(true);
        (async () => {
            try {
                if (newCoverImage) {
                    const mainImage = await imageDataFromFile(newCoverImage);
                    const thumbnailImageData = await resizeImageDataUrl(mainImage.image, 500, 500)

                    const coverImageData = {
                        imageBlob: mainImage.blob,
                        imageWidth: mainImage.imageData.width,
                        imageHeight: mainImage.imageData.height,
                        thumbnailBlob: thumbnailImageData.blob,
                        thumbnailWidth: thumbnailImageData.imageData.width,
                        thumbnailHeight: thumbnailImageData.imageData.height
                    }

                    await Playlist.updateCover(playlist, coverImageData)
                }

                await Playlist.updateMeta(playlist, { title: title, description: description })

                props.onRefresh()
                props.onClose()
            } catch (e) {
                alert(e)
            }

            setIsSaving(false)
        })()
    }


    function coverPhotoURL() {
        if (coverImage) {
            return coverImage.thumbnailUrl
        }

        if (newCoverImage) {
            return URL.createObjectURL(newCoverImage)
        }

        return ""
    }

    function deleteCoverImage() {
        setCoverImage(null)
        setNewCoverImage(null)
    }

    return (
        < div className='modal is-active' >
            <div className='modal-background' onClick={props.onClose}></div>
            <div className='modal-card'>
                <header className='modal-card-head'>
                    <p className="modal-card-title">Edit Playlist</p>
                    <button className='delete' onClick={props.onClose}></button>
                </header>

                <section className='modal-card-body has-background-white'>
                    {errorMessages.length > 0 &&
                        <article className="message is-danger">
                            <div className="message-header">
                                <p></p>
                                <button className="delete" onClick={() => setErrorMessages([])}></button>
                            </div>
                            <div className="message-body">
                                {errorMessages.map(e => (<p>{e}</p>))}
                            </div>
                        </article>
                    }

                    {/* Title */}
                    <div className="block">
                        <div className="field">
                            <p className="control is-expanded">
                                <input placeholder='Name' type="text" className="input" defaultValue={title} onChange={(e => setTitle(e.target.value))} />
                            </p>
                        </div>

                        {/* Description */}
                        <div className="field">
                            <p className="control is-expanded">
                                <textarea placeholder='Description' defaultValue={description} className="textarea" onChange={(e => setDescription(e.target.value))}></textarea>
                            </p>
                        </div>
                    </div>

                    {/* Cover Photo */}
                    <div className="block">

                        <div className="field is-grouped">
                            {/* Upload Button */}
                            <div className="control">
                                <div className="file is-light">
                                    <label className="file-label">
                                        <input className="file-input" type="file" multiple accept='.png, .jpg, .jpeg' onChange={(e => setNewCoverImage(e.target.files[0]))} />
                                        <span className="file-cta">
                                            <span className="file-icon">
                                                <i className="fas fa-upload"></i>
                                            </span>
                                            <span className="file-label">
                                                Upload Cover
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            {/* Delete Button */}
                            {
                                (newCoverImage || coverImage) &&
                                <div className="control">
                                    <button className="button is-danger is-outlined" onClick={deleteCoverImage}>Delete</button>
                                </div>
                            }
                        </div>

                        {/* Cover Photo Preview */}
                        <div className='block columns'>
                            <div className="column is-6">
                                <img className='has-background-color' src={coverPhotoURL()} alt='' />
                            </div>
                        </div>
                    </div>

                </section>

                <footer className='modal-card-foot'>
                    <button className={"button is-success" + (isSaving ? " is-loading" : "")} onClick={handleSave}>Save</button>
                </footer>
            </div>
        </div >
    )
}

export default EditPlaylistInfoModal
import React, { useState } from 'react'
import { HomeLayout } from '../../module/server/HomeLayout'

function CreateLayoutModal(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState("")

    const handleClose = () => {
        props.onClose()
    }

    const handleSubmit = () => {
        setIsLoading(true)
        HomeLayout.new({ name: name })
            .then(() => {
                props.onRefresh()
                handleClose()
            })
            .catch(e => alert(e))
            .finally(() => setIsLoading(false))
    }

    return (
        <div className="modal is-active" >
            <div className='modal-background' onClick={handleClose}></div>
            <div className='modal-card '>
                <header className='modal-card-head'>

                    <p className="modal-card-title"></p>
                    <button className='delete' onClick={handleClose}></button>
                </header>

                <section className='modal-card-body has-background-white'>

                    <div className="content">
                        <div className="field">
                            <p className="control is-expanded">
                                <input type="text" placeholder='Name' className="input" onChange={(e) => setName(e.target.value)} />
                            </p>
                        </div>
                    </div>

                </section>

                <footer className='modal-card-foot'>
                    <button className={"button is-success" + (isLoading ? " is-loading" : "")} onClick={handleSubmit}>Save</button>
                </footer>
            </div>
        </div >
    )
}

export default CreateLayoutModal
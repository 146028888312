import React, { useCallback, useEffect, useState } from 'react'
import { imageDataFromFile, resizeImageDataUrl } from '../../module/ImageUtils'
import { Artist } from '../../module/server/Artist'

function UploadImagesModal(props) {
    const [filesAndPreviews, setFilesAndPreviews] = useState([])
    const [isUploading, setIsUploading] = useState(false)
    const [toUpload, setToUpload] = useState([])

    const artistId = props.artistId
    const onCloseClick = props.onCloseClick
    const onRefresh = props.onRefresh

    const handleClose = useCallback(() => {
        // setFilesAndPreviews([])
        // setIsUploading(false)
        onCloseClick()
    }, [onCloseClick])

    useEffect(() => {
        if (!isUploading) { return }

        let leftToUpload = toUpload.slice()

        if (leftToUpload.length > 0) {
            (async () => {
                try {
                    let image = leftToUpload.pop()
                    const mainImage = await imageDataFromFile(image.file);
                    const thumbnailImageData = await resizeImageDataUrl(mainImage.image, 500, 500)

                    let uploadObj = {
                        imageWidth: mainImage.imageData.width,
                        imageHeight: mainImage.imageData.height,
                        thumbnailWidth: thumbnailImageData.imageData.width,
                        thumbnailHeight: thumbnailImageData.imageData.height,
                        meta: {
                            title: image.title ?? "",
                            description: image.description ?? "",
                            tags: image.tags ?? []
                        }

                    }

                    await Artist.uploadArtwork(artistId, uploadObj, mainImage.blob, thumbnailImageData.blob)
                    setToUpload(leftToUpload)

                } catch (e) {
                    alert(e)
                    setIsUploading(false)
                }
            })();
        } else {
            onRefresh()
            handleClose()
        }
    }, [isUploading, toUpload, artistId, handleClose, onRefresh])

    function handleImageDelete(index) {
        let files = filesAndPreviews.slice()
        files.splice(index, 1)
        setFilesAndPreviews(files)
    }

    function uploadArtworks() {
        setToUpload(filesAndPreviews.slice())
        setIsUploading(true)
    }

    useEffect(() => {
        let filesAndPreviews = props.files.map(e => ({ file: e, preview: URL.createObjectURL(e) }))
        setFilesAndPreviews(filesAndPreviews)
    }, [props.files])

    function handleDeleteTag(index, tag) {
        const files = filesAndPreviews.slice()
        files[index].tags = files[index].tags.filter(e => e !== tag)
        setFilesAndPreviews(files)
    }

    function handleTagsKeyDown(index, e) {
        if (e.key === "Enter") {
            const inTags = e.target.value.split(",").map(e => e.trim())
            e.target.value = ""

            const files = filesAndPreviews.slice()
            if (files[index].tags === undefined) { files[index].tags = [] }
            files[index].tags = files[index].tags.concat(inTags)
            setFilesAndPreviews(files)
        }
    }

    const imagePreviews = (
        filesAndPreviews.map((file, index) => (
            <article key={file.preview} className='media'>
                <figure className='media-left'>
                    <p className='image is-128x128'>
                        <figure className="is-artwork-preview">
                            <img className='has-background-color' src={file.preview} alt='' />
                        </figure>
                    </p>
                </figure>

                <div className='media-content'>
                    <div className='field'>
                        <div className='control'>
                            <input className='input' type='text' placeholder='Title' onChange={e => file.title = e.target.value} />
                        </div>
                    </div>

                    <div className='field'>
                        <div className='control'>
                            <textarea className='textarea' placeholder='Description' onChange={e => file.description = e.target.value} />
                        </div>
                    </div>
                    <div className="field is-grouped">
                        <div className='tags are-medium'>
                            {file.tags && file.tags.map((e, i) => (
                                <span key={e + i} className="tag">{e}<button className="delete is-small" onClick={() => handleDeleteTag(index, e)}></button></span>
                            ))}

                            <span className="field"><span className="control"><input className='input is-small' type="text" placeholder='Tags' onKeyDown={(e) => handleTagsKeyDown(index, e)} /></span></span>
                        </div>
                    </div>

                    <div className="field">
                        <p className="control">
                            <button className='button is-outlined is-danger is-small' onClick={() => handleImageDelete(index)}>
                                <span>Remove</span>
                                <span className="icon is-small">
                                    <i className="fas fa-times"></i>
                                </span>
                            </button>
                        </p>
                    </div>
                </div>
            </article>
        ))
    );

    return (
        < div className='modal is-active' >
            <div className='modal-background' onClick={handleClose}></div>
            <div className='modal-card'>
                <header className='modal-card-head'>
                    <p className="modal-card-title">Upload artworks</p>
                    <button className='delete' onClick={handleClose}></button>
                </header>

                <section className='modal-card-body has-background-white'>

                    {imagePreviews}

                </section>

                <footer className='modal-card-foot'>
                    <button className={"button is-success" + (isUploading ? " is-loading" : "")} onClick={uploadArtworks}>Upload</button>
                    {isUploading &&
                        <span>{"Images left to upload: " + toUpload.length}</span>
                    }
                </footer>
            </div>
        </div >
    )
}

export default UploadImagesModal
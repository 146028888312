import React from 'react'

function ArtworkModal(props) {
    const artwork = props.artwork

    return (
        <div className="modal is-active" onClick={props.onClose}>
            <div className="modal-background" />
            <div className="modal-content">
                <p className="title has-text-centered has-text-light">{artwork.meta.title}</p>
                <figure className="is-artwork-preview">
                    <img className='has-background-color' src={artwork.imageUrl} width={artwork.imageWidth} height={artwork.imageHeight} alt="" />
                </figure>
                <p className='has-text-centered has-text-light'>{artwork.meta.description}</p>
            </div>
        </div>
    )
}

export default ArtworkModal
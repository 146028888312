import React, { Fragment, useEffect, useState } from 'react'
import { HomeBlock } from '../../module/server/HomeBlock'
import { Playlist } from '../../module/server/Playlist'
import SelectPlaylistModal from './SelectPlaylistModal'

function CreateBlockModal(props) {
    let block = props.block
    const [isLoading, setIsLoading] = useState(false)
    const [title, setTitle] = useState(null)
    const [description, setDescription] = useState(null)
    const [blockType, setBlockType] = useState("playlist")
    const [showSelectPlaylistModal, setShowSelectPlaylistModal] = useState(false)
    const [playlists, setPlaylists] = useState([])

    useEffect(() => {
        if (block === undefined) return;

        Playlist.all()
            .then(e => {
                setPlaylists(e.filter(e => block.playlists.includes(e.id)))
            })

        setTitle(block.meta.title)
        setDescription(block.meta.description)
        setBlockType(block.type)
    }, [block])


    const handleClose = () => {
        props.onClose()
    }

    const handleSubmit = () => {
        setIsLoading(true)

        if (block) {
            block.meta = {
                title: title,
                description: description
            }
            block.type = blockType
            block.playlists = playlists.map(e => e.id)

            HomeBlock.update(block)
                .then(() => {
                    props.onRefresh()
                    handleClose()
                })
                .catch(e => alert(e))
                .finally(() => setIsLoading(false))
        } else {
            let data = {
                meta: {
                    title: title,
                    description: description,
                },
                type: blockType,
                playlists: playlists.map(e => e.id)
            }
            HomeBlock.new(data)
                .then(() => {
                    props.onRefresh()
                    handleClose()
                })
                .catch(e => alert(e))
                .finally(() => setIsLoading(false))
        }
    }

    const handleTypeChange = (e) => {
        setBlockType(e.target.value)
        setPlaylists([])
    }

    const handlePlaylistButton = () => {
        setShowSelectPlaylistModal(true)
    }

    const handleAddPlaylists = (p) => {
        setPlaylists(p)
    }

    function handleRemovePlaylist(id) {
        setPlaylists(playlists.filter(e => e.id !== id).slice())
    }

    return (
        <Fragment>
            <div className="modal is-active" >
                <div className='modal-background' onClick={handleClose}></div>
                <div className='modal-card '>
                    <header className='modal-card-head'>
                        <p className="modal-card-title"></p>
                        <button className='delete' onClick={handleClose}></button>
                    </header>

                    <section className='modal-card-body has-background-white'>

                        <div className="content">
                            <div className="block">
                                <div className="field">
                                    <div className="control">
                                        <label className="radio">
                                            <input type="radio" name="blockType" value="playlist" checked={blockType === "playlist"} onChange={handleTypeChange} />
                                            <span className='p-1'>Playlist</span>
                                        </label>
                                        <label className="radio">
                                            <input type="radio" name="blockType" value="group" checked={blockType === "group"} onChange={handleTypeChange} />
                                            <span className='p-1'>Group</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="field">
                                    <p className="control is-expanded">
                                        <input className="input" type="text" placeholder="Title" onChange={e => setTitle(e.target.value)} value={title ?? ""} />
                                    </p>
                                </div>
                                <div className="field">
                                    <p className="control is-expanded">
                                        <textarea className='textarea' placeholder='Description' onChange={e => setDescription(e.target.value)} value={description ?? ""} />
                                    </p>
                                </div>
                            </div>

                            <div className="block">
                                <div className="field">
                                    <div className="control">
                                        <button className="button" onClick={handlePlaylistButton}>{blockType === "playlist" ? "Select Playlist" : "Add Playlists"}</button>
                                    </div>
                                </div>

                                <div className="block">
                                    <div className="columns is-multiline">
                                        {playlists.map((e, i) => (
                                            <div key={e.id} className="column is-3">
                                                <div className='is-relative'>
                                                    <img id={e.id} src={e.coverImage && e.coverImage.thumbnailUrl} alt="" className="has-background-color" width={e.coverImage.thumbnailWidth} height={e.coverImage.thumbnailHeight} />
                                                    <button className="button is-danger" style={{ position: "absolute", bottom: "10px", right: "10px" }} onClick={() => handleRemovePlaylist(e.id)}>
                                                        <span className="icon">
                                                            <i className="fa-solid fa-trash-can"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                                <label className="label">{e.meta.title}</label>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                    <footer className='modal-card-foot'>
                        <button className={"button is-success" + (isLoading ? " is-loading" : "")} onClick={handleSubmit}>Save</button>
                    </footer>
                </div>
            </div >
            {showSelectPlaylistModal &&
                <SelectPlaylistModal
                    onAddPlaylists={handleAddPlaylists}
                    allowMultipleSelection={blockType === "group"}
                    onClose={() => setShowSelectPlaylistModal(false)}
                    selectedIds={playlists.map(e => e.id)}
                />
            }
        </Fragment>
    )
}

export default CreateBlockModal
import { deleteObject, ref } from "firebase/storage"
import { storage } from "../firebase"


Object.copyValues = function (from, to) {
    Object.keys(from).forEach(e => to[e] = from[e])
}

export function flatSnapshot(snapshot) {
    let result = { id: snapshot.id }
    Object.copyValues(snapshot.data(), result)
    return result
}

export function stripId(obj) {
    let newObj = Object.assign({}, obj)
    if (newObj.id) {
        delete newObj.id
    }
    return newObj
}

export async function deleteStoredRemote(...filePaths) {
    try {
        for (const path of filePaths) {
            await deleteObject(ref(storage, path))
        }
    } catch (e) {

    }
}
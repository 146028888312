import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { HomeBlock } from '../module/server/HomeBlock';
import Loader from "react-js-loader"
import PlaylistBlock from '../component/home-block/PlaylistBlock';
import GroupBlock from '../component/home-block/GroupBlock';
import CreateBlockModal from '../component/home/CreateBlockModal';

function SingleHomeBlock() {
    const [block, setBlock] = useState(null)
    const [showEditModal, setShowEditModal] = useState(false)

    const { blockId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        HomeBlock.block(blockId)
            .then(e => setBlock(e))
            .catch(e => alert(e))
    }, [blockId]);

    const titleView = () => (
        <Fragment>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <button className="button" onClick={() => navigate(-1)}>
                            <span className="icon">
                                <i className="fas fa-arrow-left"></i>
                            </span>
                            <span>Back</span>
                        </button>
                    </div>
                    <div className="level-item">
                        <p className='title'>{block.meta.title}<span className='is-size-5'>{" (" + block.type + ")"}</span></p>
                    </div>
                </div>
            </div>
        </Fragment>
    )

    function handleRefresh() {
        HomeBlock.block(blockId)
            .then(e => setBlock(e))
            .catch(e => alert(e))
    }

    const renderBlock = () => {
        switch (block.type) {
            case "playlist":
                return <PlaylistBlock block={block} />
            case "group":
                return <GroupBlock block={block} />
            default: return
        }
    }

    return (
        <Fragment>
            <div className='section'>
                {block === null
                    ?
                    <div className='container' style={{ marginTop: 100 }}>
                        <Loader bgColor={"#ccc"} size={100} />
                    </div>
                    :
                    <div className='container'>
                        {titleView()}
                        <div className="level">
                            <div className="level-left">
                                <button className="button is-primary" onClick={() => setShowEditModal(true)}>Edit Block</button>
                            </div>
                        </div>
                        <div className="block">
                            {renderBlock()}
                        </div>
                        <div className="block">{block.meta.description}</div>
                    </div>
                }
                {showEditModal &&
                    <CreateBlockModal block={block} onClose={() => setShowEditModal(false)} onRefresh={handleRefresh} />
                }
            </div>
        </Fragment>
    )
}

export default SingleHomeBlock
import { addDoc, collection, deleteDoc, doc, documentId, getDoc, getDocs, query, setDoc, where, writeBatch } from "firebase/firestore"
import { getDownloadURL, ref, updateMetadata, uploadBytes } from "firebase/storage"
import { db, checkAuth, storage, splitArrayForQuery } from "../firebase"
import { v4 as uuid } from 'uuid';
import { deleteStoredRemote, flatSnapshot, stripId } from "./FirebaseUtils";
import { Artwork } from "./Artwork";
import { Image } from "./Image";
import { FIREBASE_ROOT_COLLECTION } from "../../env"

class Playlist { }

Playlist.collectionPath = FIREBASE_ROOT_COLLECTION + "playlists"
const collectionPath = Playlist.collectionPath

/*
Move artworks order to seperate collection to make artwork deletion easier
*/
Playlist.new = async function (playlistData, coverImageData) {
    try {
        await checkAuth()

        if (coverImageData !== undefined) {
            const imageId = uuid()
            const imagePath = Image.storagePath + "playlist_images/" + imageId + ".jpg"
            const thumbnailPath = Image.storagePath + "playlist_thumbnails/" + imageId + ".jpg"
            const imageRef = ref(storage, imagePath)
            const thumbRef = ref(storage, thumbnailPath)
            await uploadBytes(imageRef, coverImageData.imageBlob)
            await uploadBytes(thumbRef, coverImageData.thumbnailBlob)

            await updateMetadata(thumbRef, { cacheControl: "public, max-age=10368000", contentType: "image/jpeg" })
            await updateMetadata(imageRef, { cacheControl: "public, max-age=10368000", contentType: "image/jpeg" })

            delete coverImageData.imageBlob
            delete coverImageData.thumbnailBlob

            coverImageData.imagePath = imagePath
            coverImageData.thumbnailPath = thumbnailPath
            coverImageData.imageUrl = await getDownloadURL(imageRef)
            coverImageData.thumbnailUrl = await getDownloadURL(thumbRef)
            coverImageData.createdAt = Date.now()
            playlistData.coverImage = coverImageData
        }

        playlistData.createdAt = Date.now()
        await addDoc(collection(db, collectionPath), playlistData)
    } catch (err) {
        throw err
    }
}

Playlist.delete = async function (playlistId) {
    try {
        await checkAuth()

        const playlistRef = doc(db, collectionPath, playlistId)
        const playlistSnapshot = await getDoc(playlistRef)

        const playlist = flatSnapshot(playlistSnapshot)
        if (playlist.coverImage !== undefined) {
            await deleteStoredRemote(playlist.coverImage.imagePath, playlist.coverImage.thumbnailPath)
        }
        await deleteDoc(playlistRef)
    } catch (err) {
        throw err
    }
}

Playlist.all = async function () {
    try {
        await checkAuth()

        const snapshots = await getDocs(collection(db, collectionPath))
        return snapshots.docs.map(e => flatSnapshot(e))
    } catch (err) {
        throw err
    }
}

Playlist.playlist = async function (id) {
    const snapshot = await getDoc(doc(db, collectionPath, id))
    return flatSnapshot(snapshot)
}

Playlist.update = async function (playlist, artworksAndOrder) {
    playlist.updatedAt = Date.now()
    playlist.artworks = artworksAndOrder.map(e => e.artwork.id)

    playlist.artworksOrder = artworksAndOrder.map(e => { return { order: e.order, id: e.artwork.id } })
    await setDoc(doc(db, collectionPath, playlist.id), stripId(playlist))
}

Playlist.updateMeta = async function (playlist, meta) {
    await setDoc(doc(db, collectionPath, playlist.id), { meta: meta, updatedAt: Date.now() }, { merge: true })
}

Playlist.published = async function (playlistId, isPublished) {
    await setDoc(doc(db, collectionPath, playlistId), { isPublished: isPublished }, { merge: true })
}

Playlist.setFree = async function (playlistId, isFree) {
    await setDoc(doc(db, collectionPath, playlistId), { isFree: isFree }, { merge: true })
}


Playlist.updateCover = async function (playlist, coverImageData) {
    //remove current
    if (playlist.coverImage) {
        await deleteStoredRemote(playlist.coverImage.imagePath, playlist.coverImage.thumbnailPath)
    }

    if (coverImageData) {
        const imageId = uuid()
        const imagePath = Image.storagePath + "playlist_images/" + imageId + ".jpg"
        const thumbnailPath = Image.storagePath + "playlist_thumbnails/" + imageId + ".jpg"
        const imageRef = ref(storage, imagePath)
        const thumbRef = ref(storage, thumbnailPath)
        await uploadBytes(imageRef, coverImageData.imageBlob)
        await uploadBytes(thumbRef, coverImageData.thumbnailBlob)

        await updateMetadata(thumbRef, { cacheControl: "public, max-age=10368000", contentType: "image/jpeg" })
        await updateMetadata(imageRef, { cacheControl: "public, max-age=10368000", contentType: "image/jpeg" })

        delete coverImageData.imageBlob
        delete coverImageData.thumbnailBlob

        coverImageData.imagePath = imagePath
        coverImageData.thumbnailPath = thumbnailPath
        coverImageData.imageUrl = await getDownloadURL(imageRef)
        coverImageData.thumbnailUrl = await getDownloadURL(thumbRef)
        coverImageData.createdAt = Date.now()

        await setDoc(doc(db, collectionPath, playlist.id), { coverImage: coverImageData, updatedAt: Date.now() }, { merge: true })
    }

}

Playlist.artworks = async function (playlist) {
    const imageIds = playlist.artworks ?? []
    if (playlist.artworks.length > 0) {
        //firestore max query inputs = 10
        const imageIdsSplit = splitArrayForQuery(imageIds, 10)

        let artworkDocs = []
        for (const imageIds of imageIdsSplit) {
            const q = query(collection(db, Artwork.collectionPath), where(documentId(), "in", imageIds))
            const snapshots = await getDocs(q)
            artworkDocs = artworkDocs.concat(snapshots.docs)
        }

        const artworks = artworkDocs.map(e => flatSnapshot(e))

        return artworks
    } else {
        return []
    }
}

Playlist.removeArtwork = async function (artworkId) {

    const q = query(collection(db, collectionPath), where("artworks", "array-contains", artworkId))
    const snapshots = await getDocs(q)
    const batch = writeBatch(db)
    for (const playlistSnapshot of snapshots.docs) {
        const playlistData = playlistSnapshot.data()
        let artworks = playlistData.artworks.filter(e => e !== artworkId)
        let artworksOrder = playlistData.artworksOrder.filter(e => e.id !== artworkId)
        batch.update(playlistSnapshot.ref, { artworks: artworks, artworksOrder: artworksOrder })
    }

    await batch.commit()
}

Playlist.addArtworks = async function (playlistId, artworkIds) {
    const snapshot = await getDoc(doc(db, collectionPath, playlistId))
    const playlist = flatSnapshot(snapshot)
    let artworksOrder = playlist.artworksOrder ?? []
    let artworks = playlist.artworks ?? []
    let maxOrder = artworksOrder.length > 0 ? Math.max(...artworksOrder.map(e => e.order)) : 0
    artworks = artworks.concat(artworkIds)
    artworksOrder = artworksOrder.concat(artworkIds.map((e, i) => { return { id: e, order: maxOrder + i + 1 } }))

    await setDoc(snapshot.ref, { artworksOrder: artworksOrder, artworks: artworks }, { merge: true })
}

export { Playlist }

import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore"
import { db } from "../firebase"
import { flatSnapshot } from "./FirebaseUtils";
import { FIREBASE_ROOT_COLLECTION } from "../../env"

class HomeLayout { }

HomeLayout.collectionPath = FIREBASE_ROOT_COLLECTION + "homeLayouts"
const collectionPath = HomeLayout.collectionPath

HomeLayout.all = async function () {
    const snapshots = await getDocs(collection(db, collectionPath))
    return snapshots.docs.map(e => flatSnapshot(e))
}

HomeLayout.new = async function (data) {
    data.createdAt = Date.now()
    data.isActive = false
    await addDoc(collection(db, collectionPath), data)
}

HomeLayout.makeActive = async function (id) {
    const q = query(collection(db, collectionPath), where("isActive", "==", true))
    const snapshots = await getDocs(q)
    let oldActive = null
    if (snapshots.docs.length > 0) {
        oldActive = snapshots.docs[0]
    }

    await setDoc(doc(db, collectionPath, id), { isActive: true }, { merge: true })

    if (oldActive != null) {
        await setDoc(oldActive.ref, { isActive: false }, { merge: true })
    }

    // const newActive
    return { oldActiveId: oldActive !== null ? oldActive.id : null, newActiveId: id }
}

HomeLayout.delete = async function (id) {
    await deleteDoc(doc(db, collectionPath, id))
}

HomeLayout.layout = async function (id) {
    const snapshot = await getDoc(doc(db, collectionPath, id))
    return flatSnapshot(snapshot)
}

HomeLayout.updateBlocks = async function (id, blocksAndOrder) {
    await setDoc(doc(db, collectionPath, id), {
        blocks: blocksAndOrder.map(e => e.id),
        blocksOrder: blocksAndOrder,
        updatedAt: Date.now()
    }, { merge: true })
}

HomeLayout.removeBlocks = async function (layout, blockIds) {
    const layoutSnapshot = await getDoc(doc(db, collectionPath, layout.id))
    const blocks = layoutSnapshot.data().blocks.filter(e => !blockIds.includes(e))
    const blocksOrder = layoutSnapshot.data().blocksOrder.filter(e => !blockIds.includes(e.id))

    await setDoc(doc(db, collectionPath, layout.id), { blocksOrder: blocksOrder, blocks: blocks }, { merge: true })
}

export { HomeLayout }
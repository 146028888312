import { doc, getDoc } from "firebase/firestore"
import { db } from "../firebase"
import { FIREBASE_ROOT_COLLECTION } from "../../env"

class ServerSettings { }

ServerSettings.collectionPath = FIREBASE_ROOT_COLLECTION + "settings"
const collectionPath = ServerSettings.collectionPath

ServerSettings.adminUsers = async function () {
    const snapshot = await getDoc(doc(db, collectionPath, "adminUsers"))
    return snapshot.data().users
}

export { ServerSettings }
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HomeBlock } from '../../module/server/HomeBlock';
import { formatDate } from "../../module/Utils"

function BlocksList(props) {
    const [deleteingId, setDeleteingId] = useState("")

    const navigate = useNavigate();
    const handleDelete = (block) => {
        if (window.confirm("Delete " + block.title + "?")) {
            setDeleteingId(block.id)

            HomeBlock.delete(block.id)
                .then(_ => {
                    props.onDeleted(block)
                })
                .catch(e => alert(e))
                .finally(_ => setDeleteingId(""))
        }
    };

    function renderType(type) {
        const iconName = type === "playlist" ? "fa-regular fa-square" : "fa-regular fa-object-group"

        return (
            <Fragment>
                <p className="is-medium tag">
                    <span className="icon">
                        <i className={iconName}></i>
                    </span>
                    <span className=''>{type}</span>
                </p>
            </Fragment>
        )
    }

    return (
        <table className='table is-hoverable is-fullwidth'>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Created</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    props.blocks.sort((a, b) => a.createdAt < b.createdAt).map(block => (
                        <tr key={block.id} className="is-clickable" onClick={() => navigate("/home-blocks/" + block.id)}>
                            <td>{block.meta.title}</td>
                            <td>{renderType(block.type)}</td>
                            <td>{formatDate(block.createdAt)}</td>
                            <td>
                                <div className='field is-grouped is-grouped-right'>
                                    <p className='control'>
                                        <button className={"button is-danger " + (deleteingId === block.id ? "is-loading is-outlined" : "is-inverted")} onClick={e => { e.stopPropagation(); handleDelete(block) }}>
                                            <span className="icon is-small">
                                                <i className="fas fa-times"></i>
                                            </span>
                                            <span>Delete</span>
                                        </button>
                                    </p>
                                </div>
                            </td>
                        </tr >
                    ))
                }

            </tbody>
        </table>
    )
}

export default BlocksList

import React from 'react'

function MetaView(props) {
    const data = props.data

    return (
        <div id="meta">
            <div className="block">
                <span className='is-size-6 has-text-weight-bold'>Country</span>
                <p className='is-size-6'>{data.country}</p>
            </div>
            <div className='block'>
                <span className='is-size-6 has-text-weight-bold'>Short Description</span>
                <p className='is-size-6'>{data.shortDescription}</p>
            </div>
            <div className='block'>
                <span className='is-size-6 has-text-weight-bold'>Long Description</span>
                <p className='is-size-6'>{data.longDescription}</p>
            </div>
            <div className="block">
                <span className="is-size-6 has-text-weight-bold">Note</span>
                <p className="is-size-6">{data.note}</p>
            </div>
            <div className='block'>
                <div className='level'>
                    <div className="level-left">
                        <div className="level-item">
                            <span className='icon'><i className="fa-brands fa-safari"></i></span>
                            <span><a target='_blank' rel="noreferrer noopener" className='link' href={data.websiteUrl}>{data.websiteUrl}</a></span>
                        </div>
                        <div className="level-item">
                            <span className='icon'><i className="fa-brands fa-instagram"></i></span>
                            <span>@{data.socialMedia.instagram}</span>
                        </div>
                        <div className="level-item">
                            <span className='icon'><i className="fa-brands fa-twitter"></i></span>
                            <span>@{data.socialMedia.twitter}</span>
                        </div>
                    </div>

                </div>
            </div>
            <div className="block">
                <span className="is-size-6 has-text-weight-bold">Tags</span>
                <div className='tags are-medium'>
                    {data.tags && data.tags.map((e, i) => (
                        <span key={e + i} className="tag">{e}</span>
                    ))}
                </div>
            </div>
            <div className="block"><button className="button is-light" onClick={props.onEdit}>
                <span className="icon is-small">
                    <i className="fa-solid fa-pen-to-square"></i>
                </span>
                <span>Edit Info</span>
            </button></div>
        </div>
    )
}

export default MetaView
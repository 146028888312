import { useState } from 'react'
import { Playlist } from '../../module/server/Playlist'

function PremiumButtons(props) {
    const playlist = props.playlist
    const [isFree, setIsFree] = useState(playlist.isFree ?? false)
    const [isLoading, setIsLoading] = useState(false)

    function handleClick() {
        setIsLoading(true)

        if (isFree === true) {
            setIsFree(false)
            Playlist.setFree(playlist.id, false)
                .finally(() => setIsLoading(false))
        } else {
            setIsFree(true)
            Playlist.setFree(playlist.id, true)
                .finally(() => setIsLoading(false))
        }
    }

    return (
        <button className={"button " + (isLoading ? " is-loading" : "")} onClick={handleClick} >{isFree ? "Free" : "Premium"}</button>
    )
}

export default PremiumButtons
import React from 'react'
import { NavLink } from 'react-router-dom'
import { signOut } from '../module/firebase'

function SideMenu() {
    let link = (to, title) => (<li><NavLink className={({ isActive }) => isActive ? 'is-active' : ''} to={to}>{title}</NavLink></li>)

    return (
        <div className='section'>
            <aside className='menu'>
                <p className='menu-label'>Playlists</p>
                <ul className='menu-list'>
                    {link('/', 'Dashboard')}
                    {link('/home-layouts', 'Home Layouts')}
                    {link('/playlists', 'Playlists')}
                    {link('/artists', 'Artists')}
                </ul>
                <p className='menu-label'>Account</p>
                <ul className='menu-list'>
                    <li><button className='button is-link is-inverted is-light' onClick={signOut}>Sign Out</button></li>
                </ul>
            </aside>
        </div>
    )
}

export default SideMenu
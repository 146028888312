import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Artist } from '../../module/server/Artist.js'

function ArtistsList(props) {
    const [deleteingId, setDeleteingId] = useState("")

    const navigate = useNavigate();

    const onDelete = (artist) => {
        if (window.confirm("Delete " + artist.name + "?")) {
            setDeleteingId(artist.id)
            Artist.delete(artist.id)
                .then(() => {
                    props.onRefresh()
                })
                .catch(e => alert(e))
        }
    };

    const list = () => (
        props.artists.sort((a, b) => b.createdAt - a.createdAt).map(artist => (
            <tr key={artist.id} className="is-clickable" onClick={() => navigate(artist.id)}>
                <td>
                    <figure className='image is-32x32'>
                        <img className='is-rounded' src="https://bulma.io/images/placeholders/128x128.png" alt="" />
                    </figure>
                </td>
                <td>{artist.name}</td>
                <td>{artist.meta.tags !== undefined && artist.meta.tags.map(e => (<span className='is-medium tag mx-1'>{e}</span>))}</td>
                <td>{artist.meta.country}</td>
                <td>
                    <div className='field is-grouped is-grouped-right'>
                        <p className='control'>
                            <button className={"button is-danger " + (deleteingId === artist.id ? "is-outlined is-loading" : "is-inverted")} onClick={e => { e.stopPropagation(); onDelete(artist) }}>
                                <span className="icon is-small">
                                    <i className="fas fa-times"></i>
                                </span>
                                <span>Delete</span>
                            </button>
                        </p>
                    </div>
                </td>
            </tr >
        ))
    );

    return (
        <table className='table is-hoverable is-fullwidth'>
            <thead>
                <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Tags</th>
                    <th>Country</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>

                {list()}

            </tbody>
        </table>
    )
}

export default ArtistsList
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore"
import { db } from "../firebase"
import { flatSnapshot, stripId } from "./FirebaseUtils";
import { HomeLayout } from "./HomeLayout"
import { FIREBASE_ROOT_COLLECTION } from "../../env"

class HomeBlock { }

HomeBlock.collectionPath = FIREBASE_ROOT_COLLECTION + "homeBlocks"
const collectionPath = HomeBlock.collectionPath

HomeBlock.all = async function () {
    const snapshots = await getDocs(collection(db, collectionPath))
    return snapshots.docs.map(e => flatSnapshot(e))
}

HomeBlock.new = async function (data) {
    data.createdAt = Date.now()
    await addDoc(collection(db, collectionPath), data)
}

HomeBlock.block = async function (id) {
    const snapshot = await getDoc(doc(db, collectionPath, id))
    return flatSnapshot(snapshot)
}

HomeBlock.update = async function (block) {
    block.updatedAt = Date.now()
    await setDoc(doc(db, collectionPath, block.id), stripId(block), { merge: true })
}

HomeBlock.updateMeta = async function (block, meta) {
    await setDoc(doc(db, collectionPath, block.id), { meta: meta, updatedAt: Date.now() }, { merge: true })
}

HomeBlock.delete = async function (id) {
    const q = query(collection(db, HomeLayout.collectionPath), where("blocks", "array-contains", id))
    const layoutSnapshots = await getDocs(q)

    for (const layout of layoutSnapshots.docs) {
        await HomeLayout.removeBlocks(flatSnapshot(layout), [id])
    }

    await deleteDoc(doc(db, collectionPath, id))
}

export { HomeBlock }